import React from "react";
import Home from "./components/Home";
import { Routes, Route } from "react-router-dom";
import Survey from "./pages/Survey";
import SurveyDetails from "./pages/SurveyDetails";
import SurveyResult from "./pages/SurveyResult";
import ScrollToAnchor from "./components/ScrollToAnchor";
import Error404 from "./errorsPage/404Error/Error404";
import Terms from "./pages/Terms";
import Privacy from "./pages/Privacy";
import Support from "./pages/Support";

const App = () => {
  return (
    <React.Fragment>
      <ScrollToAnchor />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/survey' element={<Survey />} />
        <Route path='/survey-details/:id' element={<SurveyDetails />} />
        <Route path='/survey-results/:id' element={<SurveyResult />} />
        <Route path='/terms' element={<Terms />} />
        <Route path='/privacy' element={<Privacy />} />
        <Route path='/support' element={<Support />} />
        <Route path='*' element={<Error404 />} />
      </Routes>
    </React.Fragment>
  )
};

export default App;