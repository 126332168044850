import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Row } from 'react-bootstrap';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    Cell,
} from "recharts";

const Graph = ({ data, qData }) => {

    const [values, setValues] = useState([]);

    useEffect(() => {
        doSomething();
    }, [data]);

    const doSomething = async () => {
        const obj = qData.options?.reduce((obj, key) => {
            return { ...obj, [key]: 0 };
        }, {});

        await data?.forEach(ans => {
            obj[ans.surveyAnswer.answer] = obj[ans.surveyAnswer.answer] + 1
        });

        const newArr = [];
        Object.values(obj).map((elem, index) => {
            newArr.push({ name: index + 1, Answer: elem });
        });

        setValues(newArr);
    };

    const maxPv = Math.max(...data.map((item) => item.Answer));
    return (
        <>
            <div className="d-flex">
                <BarChart
                    width={500}
                    height={260}
                    data={values}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Legend />
                    <Bar dataKey="Answer" barSize={10}>
                        {values.map((entry, index) => (
                            <Cell
                                key={`cell-${index}`}
                                fill={
                                    entry.Answer === maxPv
                                        ? "#20C6FB"
                                        : "#7E879F"
                                }
                            />
                        ))}
                    </Bar>
                </BarChart>
            </div>
            {
                qData?.comment &&
                <Row className="mt-3 mb-3">
                    <Col>
                        <h4>Comments</h4>
                        {
                            data && data?.length > 0 &&
                            data.map((elem, i) => (
                                <React.Fragment key={i}>
                                    {
                                        elem?.surveyAnswer?.comment !== "" &&
                                        <Card style={{ marginBottom: "5px" }}>
                                            <CardBody>
                                                {elem?.surveyAnswer?.comment}
                                            </CardBody>
                                        </Card>
                                    }
                                </React.Fragment>
                            ))
                        }
                    </Col>
                </Row>
            }
        </>
    )
}

export default Graph;