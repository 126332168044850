import React from 'react'
import { Link } from 'react-router-dom'

export default function Navigation() {
  return (
    <>
       <nav
          className="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-light bg-light"
          id="navbar"
        >
          <div className="container-fluid">
            {/* LOGO */}
            <Link className="navbar-brand logo text-uppercase" to={"/"}>
              <img
                src="images/logo-light.png"
                className="logo-light"
                alt=""
                height={30}
              />
              <img
                src="images/logo-dark.png"
                className="logo-dark"
                alt=""
                height={30}
              />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="mdi mdi-menu" />
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <ul className="navbar-nav ms-auto" id="navbar-navlist">
                <li className="nav-item">
                  <Link className="nav-link" to="/#home">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/#service">
                    Services
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/#features">
                    Features
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/#app">
                    Application
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/#contact">
                    Contact
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/survey">
                    Surveys
                  </Link>
                </li>
              </ul>
              {/*  <div class="ms-auto">
                    <a href="" class="btn bg-gradiant">Login</a>
                </div> */}
            </div>
          </div>
        </nav>
    </>
  )
}
