import React, { useState } from "react";
import Typewriter from "typewriter-effect";
import Slider from "react-slick";
import "./Home.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import Navigation from "./Navigation";

const Home = () => {
  const settings = {
    dots: true, // Show navigation dots
    infinite: true, // Allow infinite looping
    speed: 1000, // Transition speed in milliseconds
    slidesToShow: 1.09, // Number of slides to show at once
    slidesToScroll: 1, // Number of slides to scroll at a time
    centerMode: true,
    centerPadding: "7.5%",
    autoplay: true,       // Enable autoplay
    autoplaySpeed: 2000,
    arrows: false,
  };

  const options = {
    dots: true, // Show navigation dots
    infinite: true, // Allow infinite looping
    speed: 1000, // Transition speed in milliseconds
    slidesToShow: 5, // Number of slides to show at once
    slidesToScroll: 1, // Number of slides to scroll at a time
    centerMode: true,
    centerPadding: "0%",
    swipeToSlide: 1,
    autoplay: true,       // Enable autoplay
    autoplaySpeed: 1500,
    arrows: false,
  }
  // const [index, setIndex] = useState(0);

  // const handleSelect = (selectedIndex) => {
  //   setIndex(selectedIndex);
  // };
  return (
    <React.Fragment>
      <div>
        {/* light-dark mode button */}
        {/* START  NAVBAR */}
        <Navigation />
        {/* END NAVBAR */}
        {/* home section */}
        <section className="home-2 bg-primary" id="home">
          <div className="background-line">
            <div className="line-rounded" />
          </div>
          {/* start container */}
          <div className="container position-relative">
            {/* start row */}
            <div className="row align-items-center justify-content-center">
              <div className="col-lg-8">
                <div className="text-center margin-top-120">
                  <h1 className="home-title mb-0 text-white display-5">
                    <Typewriter
                      className="typewrite"
                      data-period={2000}
                      options={{
                        strings: [
                          "Generate Survey in minute",
                          "Usefull for company, industries and other",
                          "Chat with friends",
                        ],
                        autoStart: true,
                        loop: true,
                      }}
                    >
                      <span className="wrap" />
                    </Typewriter>
                  </h1>
                  <p className="text-white-50 mt-4">
                    This is a surveys app where you can create surveys for your
                    industry, company etc and you can select particular users
                    and target users according too your survey.
                  </p>
                </div>
              </div>
            </div>
            {/* end row */}
            <div className="phone-mockup">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-3">
                  <img
                    src="images/home/phone-2.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="col-lg-4">
                  <div className="video-preview video-container mt-4">
                    <img
                      src="images/features/phone.png"
                      alt=""
                      className="img-fluid"
                    />
                    <div className="watch-video">
                      <a
                        href
                        className="video-play-icon watch text-white"
                        data-bs-toggle="modal"
                        data-bs-target="#watchvideomodal"
                      >
                        <i className="mdi mdi-play text-center d-inline-block rounded-pill text-light bg-primary fs-30 avatar-lg me-1" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <img
                    src="images/home/phone-3.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* end container */}
          <div className="background-line-2">
            <div className="line-rounded" />
          </div>
          <div
            className="modal fade bd-example-modal-lg"
            id="watchvideomodal"
            data-keyboard="false"
            tabIndex={-1}
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog modal-lg">
              <div className="modal-content hero-modal-0 bg-transparent">
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                />
                <video id="VisaChipCardVideo" className="w-100" controls>
                  <source
                    src="https://www.w3schools.com/html/mov_bbb.mp4"
                    type="video/mp4"
                  />
                  {/*Browser does not support <video> tag */}
                </video>
              </div>
            </div>
          </div>
        </section>
        {/* end home section */}
        {/* service section */}
        <section
          className="section service home2-service bg-light"
          id="service"
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="title text-center mb-5">
                  <h6 className="mb-0 fw-bold text-primary">What We Do?</h6>
                  <h2 className="f-40">How can help you!</h2>
                  <div className="border-phone">
                    <p className="text-muted">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Dolor eos inventore omnis aliquid rerum alias molestias.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-between">
              <div className="col-lg-4">
                <div className="service-box text-center">
                  <div
                    className="service-icon p-4"
                    style={{
                      backgroundImage: "url(./images/service/bomb.png)",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                    }}
                  >
                    <i className="mdi mdi-security text-gradiant f-30" />
                  </div>
                  <div className="service-content mt-4">
                    <a href>
                      <h5 className="fw-bold">Create Surevy</h5>
                    </a>
                    <p className="text-muted">
                      Lorem ipsum dolor sit amet, adipiscing elit. Phasellus
                      hendrerit. Pellentesque aliquet nibh nec urna.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 pt-4 pt-lg-0">
                <div className="service-box text-center shadow">
                  <div
                    className="service-icon p-4"
                    style={{
                      backgroundImage: "url(./images/service/bomb.png)",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                    }}
                  >
                    <i className="mdi mdi-lightbulb-on text-gradiant f-30" />
                  </div>
                  <div className="service-content mt-4">
                    <a href>
                      <h5 className="fw-bold">Make Friends</h5>
                    </a>
                    <p className="text-muted">
                      Lorem ipsum dolor sit amet, adipiscing elit. Phasellus
                      hendrerit. Pellentesque aliquet nibh nec urna.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 pt-4 pt-lg-0">
                <div className="service-box text-center">
                  <div
                    className="service-icon p-4"
                    style={{
                      backgroundImage: "url(./images/service/bomb.png)",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                    }}
                  >
                    <i className="mdi mdi-google-nearby text-gradiant f-30" />
                  </div>
                  <div className="service-content mt-4">
                    <a href>
                      <h5 className="fw-bold">Win Prizes</h5>
                    </a>
                    <p className="text-muted">
                      Lorem ipsum dolor sit amet, adipiscing elit. Phasellus
                      hendrerit. Pellentesque aliquet nibh nec urna.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* end section */}
        {/* start features */}
        <div className="section features" id="features">
          {/* start container */}
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="title text-center mb-5">
                  <h6 className="mb-0 fw-bold text-primary">
                    AppTech Features
                  </h6>
                  <h2 className="f-40">Features for our app </h2>
                  <p className="text-muted">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Dolor eos inventore omnis aliquid rerum alias molestias.
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <ul
                  className="nav nav-pills mb-3 justify-content-center"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item mb-3" role="presentation">
                    <button
                      className="nav-link active"
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      Top Features
                    </button>
                  </li>
                  <li className="nav-item mb-3" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      Smart Features
                    </button>
                  </li>
                </ul>
                <div className="tab-content mt-5" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div className="row align-items-center">
                      <div className="col-lg-4 order-2 order-lg-first">
                        <div className="features-item text-start text-lg-end">
                          <div className="icon avatar-sm text-center ms-lg-auto rounded-circle">
                            <i className="mdi mdi-message-alert-outline f-24" />
                          </div>
                          <div className="content mt-3">
                            <h5>Responsive Design</h5>
                            <p>
                              Vivamus ac nulla ultrices laoreet neque mollis mi
                              morbi elementum .
                            </p>
                          </div>
                        </div>
                        <div className="features-item text-start text-lg-end mt-5">
                          <div className="icon avatar-sm text-center ms-lg-auto rounded-circle">
                            <i className="mdi mdi-autorenew f-24" />
                          </div>
                          <div className="content mt-3">
                            <h5>Cool Features</h5>
                            <p>
                              Vivamus ac nulla ultrices laoreet neque mollis mi
                              morbi elementum .
                            </p>
                          </div>
                        </div>
                        <div className="features-item text-start text-lg-end mt-5 mb-5">
                          <div className="icon avatar-sm text-center ms-lg-auto rounded-circle">
                            <i className="mdi mdi-eyedropper f-24" />
                          </div>
                          <div className="content mt-3">
                            <h5>Lifetime Support</h5>
                            <p>
                              Vivamus ac nulla ultrices laoreet neque mollis mi
                              morbi elementum .
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <img
                          src="images/features/phone.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div className="col-lg-4 order-last">
                        <div className="features-item">
                          <div className="icon avatar-sm text-center rounded-circle">
                            <i className="mdi mdi-lifebuoy f-24" />
                          </div>
                          <div className="content mt-3">
                            <h5>Stunning Design</h5>
                            <p>
                              Vivamus ac nulla ultrices laoreet neque mollis mi
                              morbi elementum .
                            </p>
                          </div>
                        </div>
                        <div className="features-item mt-5">
                          <div className="icon avatar-sm text-center rounded-circle">
                            <i className="mdi mdi-dropbox f-24" />
                          </div>
                          <div className="content mt-3">
                            <h5>Best PSD Pack</h5>
                            <p>
                              Vivamus ac nulla ultrices laoreet neque mollis mi
                              morbi elementum .
                            </p>
                          </div>
                        </div>
                        <div className="features-item mt-5">
                          <div className="icon avatar-sm text-center rounded-circle">
                            <i className="mdi mdi-flask f-24" />
                          </div>
                          <div className="content mt-3">
                            <h5>Creative Idea</h5>
                            <p>
                              Vivamus ac nulla ultrices laoreet neque mollis mi
                              morbi elementum .
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <div className="row align-items-center">
                      <div className="col-lg-6">
                        <img
                          src="images/features/phone2.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                      <div className="col-lg-6">
                        <h2 className="mb-4">Smart Features</h2>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="features-box mt-4">
                              <div className="d-flex">
                                <div className="icon">
                                  <i className="mdi mdi-check-circle f-20 me-2" />
                                </div>
                                <div className="heading">
                                  <h6 className="mt-1">Fast Messaging</h6>
                                  <p className="text-muted">
                                    Soluta velit sint, esse quis tempora impedit
                                    corrupti in recusandae tenetur dignissimos
                                    voluptates..
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="features-box mt-4">
                              <div className="d-flex">
                                <div className="icon">
                                  <i className="mdi mdi-check-circle f-20 me-2" />
                                </div>
                                <div className="heading">
                                  <h6 className="mt-1">User Freindly</h6>
                                  <p className="text-muted">
                                    Amet repudiandae illo quasi enim iusto
                                    corporis ratione? Laudantium reprehenderit
                                    sint provident.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="features-box mt-4">
                              <div className="d-flex">
                                <div className="icon">
                                  <i className="mdi mdi-check-circle f-20 me-2" />
                                </div>
                                <div className="heading">
                                  <h6 className="mt-1">Minimal Interface</h6>
                                  <p className="text-muted">
                                    Repellat ad in autem, odio quos ex eum
                                    recusandae cupiditate assumenda nihil
                                    incidunt dolorem qui soluta.
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="features-box mt-4">
                              <div className="d-flex">
                                <div className="icon">
                                  <i className="mdi mdi-check-circle f-20 me-2" />
                                </div>
                                <div className="heading">
                                  <h6 className="mt-1">Notification</h6>
                                  <p className="text-muted">
                                    Ipsam nisi quam velit maxime corrupti ut
                                    quos, ad eum laudantium voluptatibus,
                                    facilis numquam repellendus.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end container */}
        </div>
        {/* end features */}
        {/* start testimonial */}
        <section className="section bg-light testimonial" id="testimonial">
          {/* start container */}
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-4">
                <div className="title">
                  <p className=" text-primary fw-bold mb-0">
                    Client Testimonial{" "}
                    <i className="mdi mdi-cellphone-iphone" />
                  </p>
                  <h3>What they think of us!</h3>
                  <p className="text-muted">
                    Morbi at erat et tortor tempor sodales non eu lacus Donec at
                    gravida nunc vitae volutpat. Morbi in sem quis dui placerat
                    ornare.
                  </p>
                  <button className="btn bg-gradiant">
                    Read More Testimonial <i className="mdi mdi-arrow-right" />
                  </button>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="testi-slider" id="testi-slider">
                  {/* <Carousel touch={true}> */}
                  <Slider {...settings}>
                    {/* <Carousel.Item> */}
                    <div className="item carousel slide">
                      <div className="testi-box position-relative overflow-hidden">
                        <div className="row align-items-center">
                          <div className="col-md-5">
                            <div className="slide_img_wrapper">
                              <img
                                src="images/testi/img-1.png"
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                          <div className="col-md-7">
                            <div className="p-4">
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                  <div className="avatar">
                                    <img
                                      src="images/user/img-1.jpg"
                                      alt=""
                                      className="img-fluid rounded-circle"
                                    />
                                  </div>
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <p className="f-14 mb-0 text-dark fw-bold">
                                    <span className="text-muted fw-normal">
                                      Review By{" "}
                                    </span>{" "}
                                    Freanki Fabel
                                  </p>
                                  <div className="date">
                                    <p className="text-muted mb-0 f-14">
                                      28 jan, 2021 <span>10:25 AM</span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="mt-3">
                                <h5 className="fw-bold">
                                  Bulid The App That Everyone Love.
                                </h5>
                                <p className="text-muted f-14">
                                  Start working with Styza that can provide
                                  everything you need to generate awareness,
                                  drive traffic, connect.
                                </p>
                                <button className="btn btn-sm bg-gradiant">
                                  <i className="mdi mdi-plus f-16 align-middle" />
                                  Follow
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="back-image position-absolute end-0 bottom-0">
                          <img
                            src="images/testi/rating-image.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    {/* </Carousel.Item>
                    <Carousel.Item> */}
                    <div className="item">
                      <div className="testi-box position-relative overflow-hidden">
                        <div className="row align-items-center">
                          <div className="col-md-5">
                            <div className="slide_img_wrapper">
                              <img
                                src="images/testi/img-2.png"
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                          <div className="col-md-7">
                            <div className="p-4">
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                  <div className="avatar">
                                    <img
                                      src="images/user/img-2.jpg"
                                      alt=""
                                      className="img-fluid rounded-circle"
                                    />
                                  </div>
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <p className="f-14 mb-0 text-dark fw-bold">
                                    <span className="text-muted fw-normal">
                                      Review By{" "}
                                    </span>{" "}
                                    Freanki Fabel
                                  </p>
                                  <div className="date">
                                    <p className="text-muted mb-0 f-14">
                                      28 jan, 2021 <span>10:25 AM</span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="mt-3">
                                <h5 className="fw-bold">
                                  Easy and prefect solution
                                </h5>
                                <p className="text-muted f-14">
                                  Start working with Styza that can provide
                                  everything you need to generate awareness,
                                  drive traffic, connect.
                                </p>
                                <button className="btn btn-sm bg-gradiant">
                                  <i className="mdi mdi-plus f-16 align-middle" />
                                  Follow
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="back-image position-absolute end-0 bottom-0">
                          <img
                            src="images/testi/rating-image.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    {/* </Carousel.Item>
                    <Carousel.Item> */}
                    <div className="item ">
                      <div className="testi-box position-relative overflow-hidden">
                        <div className="row align-items-center">
                          <div className="col-md-5">
                            <div className="slide_img_wrapper">
                              <img
                                src="images/testi/img-3.png"
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                          <div className="col-md-7">
                            <div className="p-4">
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                  <div className="avatar">
                                    <img
                                      src="images/user/img-3.jpg"
                                      alt=""
                                      className="img-fluid rounded-circle"
                                    />
                                  </div>
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <p className="f-14 mb-0 text-dark fw-bold">
                                    <span className="text-muted fw-normal">
                                      Review By{" "}
                                    </span>{" "}
                                    Freanki Fabel
                                  </p>
                                  <div className="date">
                                    <p className="text-muted mb-0 f-14">
                                      28 jan, 2021 <span>10:25 AM</span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="mt-3">
                                <h5 className="fw-bold">
                                  Bulid The App That Everyone Love.
                                </h5>
                                <p className="text-muted f-14">
                                  Start working with Styza that can provide
                                  everything you need to generate awareness,
                                  drive traffic, connect.
                                </p>
                                <button className="btn btn-sm bg-gradiant">
                                  <i className="mdi mdi-plus f-16 align-middle" />
                                  Follow
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="back-image position-absolute end-0 bottom-0">
                          <img
                            src="images/testi/rating-image.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    {/* /  </Carousel.Item> */}
                    {/* slider item */}
                    {/* <Carousel.Item> */}
                    <div className="item ">
                      <div className="testi-box position-relative overflow-hidden">
                        <div className="row align-items-center">
                          <div className="col-md-5">
                            <div className="slide_img_wrapper">
                              <img
                                src="images/testi/img-4.png"
                                alt=""
                                className="img-fluid"
                              />
                            </div>
                          </div>
                          <div className="col-md-7">
                            <div className="p-4">
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                  <div className="avatar">
                                    <img
                                      src="images/user/img-4.jpg"
                                      alt=""
                                      className="img-fluid rounded-circle"
                                    />
                                  </div>
                                </div>
                                <div className="flex-grow-1 ms-3">
                                  <p className="f-14 mb-0 text-dark fw-bold">
                                    <span className="text-muted fw-normal">
                                      Review By{" "}
                                    </span>{" "}
                                    Freanki Fabel
                                  </p>
                                  <div className="date">
                                    <p className="text-muted mb-0 f-14">
                                      28 jan, 2021 <span>10:25 AM</span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="mt-3">
                                <h5 className="fw-bold">
                                  Bulid The App That Everyone Love.
                                </h5>
                                <p className="text-muted f-14">
                                  Start working with Styza that can provide
                                  everything you need to generate awareness,
                                  drive traffic, connect.
                                </p>
                                <button className="btn btn-sm bg-gradiant">
                                  <i className="mdi mdi-plus f-16 align-middle" />
                                  Follow
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="back-image position-absolute end-0 bottom-0">
                          <img
                            src="images/testi/rating-image.png"
                            alt=""
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    </div>
                    {/* </Carousel.Item> */}
                  </Slider>
                  {/* </Carousel> */}
                  {/* slider item */}
                </div>
              </div>
            </div>
          </div>
          {/* end container */}
        </section>
        {/* end testimonial */}
        {/* pricing section */}
        <section className="section pricing" id="pricing">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="title text-center mb-5">
                  <h6 className="mb-0 fw-bold text-primary">
                    What's Your Price!
                  </h6>
                  <h2 className="f-40">Pricing plans!</h2>
                  <p className="text-muted">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Dolor eos inventore omnis aliquid rerum alias molestias.
                  </p>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-4">
                <div className="price-item shadow-sm overflow-hidden">
                  <div className="price-up-box active p-4">
                    <div className="badge bg-primary fw-normal f-14">Basic</div>
                    <div className="price-tag mt-2">
                      <h2 className="text-white mb-0 f-40">
                        <sup className="f-22 fw-normal">$</sup>09
                        <sup className="f-16 fw-normal"> /month</sup>
                      </h2>
                    </div>
                    <p className="text-white-50 mb-1">17 to 19 user</p>
                    <p className="text-white-50 mb-0">
                      For most businesses that want to optimize web query.
                    </p>
                  </div>
                  <div className="border border-3" />
                  <div className="price-down-box p-4">
                    <ul className="list-unstyled ">
                      <li>
                        <i className="mdi mdi-check-circle-outline f-20 align-middle me-2 text-primary" />
                        2 App and project
                      </li>
                      <li className="mt-2">
                        <i className="mdi mdi-check-circle-outline f-20 align-middle me-2 text-primary" />
                        400 Gb/s storange
                      </li>
                      <li className="mt-2">
                        <i className="mdi mdi-check-circle-outline f-20 align-middle me-2 text-primary" />
                        Free coustom domain
                      </li>
                      <li className="mt-2">
                        <i className="mdi mdi-close-circle-outline f-20 align-middle me-2 text-danger" />
                        Chat Support
                      </li>
                      <li className="mt-2">
                        <i className="mdi mdi-close-circle-outline f-20 align-middle me-2 text-danger" />
                        No transaction fees
                      </li>
                      <li className="mt-2">
                        <i className="mdi mdi-close-circle-outline f-20 align-middle me-2 text-danger" />
                        Unlimited Storage
                      </li>
                    </ul>
                    <a href className="btn btn-sm text-primary mt-3">
                      <i className="mdi mdi-check-all me-2" />
                      your plane
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 ">
                <div className="price-item shadow-sm overflow-hidden mt-4 mt-lg-0">
                  <div className="topbar-header bg-primary py-2 text-center">
                    <h6 className="mb-0 text-white fw-normal">
                      Recommended For You
                    </h6>
                  </div>
                  <div className="price-up-box p-4">
                    <div className="badge bg-primary fw-normal f-14">
                      Startup
                    </div>
                    <div className="price-tag mt-2">
                      <h2 className="text-dark mb-0 f-40">
                        <sup className="f-22 fw-normal">$</sup>19
                        <sup className="f-16 fw-normal"> /month</sup>
                      </h2>
                    </div>
                    <p className="text-muted mb-1">20 to 40 user</p>
                    <p className="text-muted mb-0">
                      For most businesses that want to optimize web query.
                    </p>
                  </div>
                  <div className="border border-3" />
                  <div className="price-down-box p-4">
                    <ul className="list-unstyled ">
                      <li>
                        <i className="mdi mdi-check-circle-outline f-20 align-middle me-2 text-primary" />
                        15 App and project
                      </li>
                      <li className="mt-2">
                        <i className="mdi mdi-check-circle-outline f-20 align-middle me-2 text-primary" />
                        800 Gb/s storange
                      </li>
                      <li className="mt-2">
                        <i className="mdi mdi-check-circle-outline f-20 align-middle me-2 text-primary" />
                        Free coustom domain
                      </li>
                      <li className="mt-2">
                        <i className="mdi mdi-check-circle-outline f-20 align-middle me-2 text-primary" />
                        Chat Support
                      </li>
                      <li className="mt-2">
                        <i className="mdi mdi-close-circle-outline f-20 align-middle me-2 text-danger" />
                        No transaction fees
                      </li>
                      <li className="mt-2">
                        <i className="mdi  mdi-close-circle-outline f-20 align-middle me-2 text-danger" />
                        Unlimited Storage
                      </li>
                    </ul>
                    <a href className="btn btn-sm btn-primary mt-3">
                      <i className="mdi mdi-check-all me-2" />
                      Buy Now
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="price-item shadow-sm overflow-hidden mt-4 mt-lg-0">
                  <div className="price-up-box p-4">
                    <div className="badge bg-primary fw-normal f-14">
                      Enterprise
                    </div>
                    <div className="price-tag mt-2">
                      <h2 className="text-dark mb-0 f-40">
                        <sup className="f-22 fw-normal">$</sup>29
                        <sup className="f-16 fw-normal"> /month</sup>
                      </h2>
                    </div>
                    <p className="text-muted mb-1">17 to 19 user</p>
                    <p className="text-muted mb-0">
                      For most businesses that want to optimize web query.
                    </p>
                  </div>
                  <div className="border border-3" />
                  <div className="price-down-box p-4">
                    <ul className="list-unstyled ">
                      <li>
                        <i className="mdi mdi-check-circle-outline f-20 align-middle me-2 text-primary" />
                        Unlimited App and project
                      </li>
                      <li className="mt-2">
                        <i className="mdi mdi-check-circle-outline f-20 align-middle me-2 text-primary" />
                        1000 Gb/s storange
                      </li>
                      <li className="mt-2">
                        <i className="mdi mdi-check-circle-outline f-20 align-middle me-2 text-primary" />
                        Free coustom domain
                      </li>
                      <li className="mt-2">
                        <i className="mdi mdi-check-circle-outline f-20 align-middle me-2 text-primary" />
                        Chat Support
                      </li>
                      <li className="mt-2">
                        <i className="mdi mdi-check-circle-outline f-20 align-middle me-2 text-primary" />
                        No transaction fees
                      </li>
                      <li className="mt-2">
                        <i className="mdi mdi-check-circle-outline f-20 align-middle me-2 text-primary" />
                        Unlimited Storage
                      </li>
                    </ul>
                    <a href className="btn btn-sm btn-primary mt-3">
                      <i className="mdi mdi-check-all me-2" />
                      Buy Now
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* end pricing */}
        {/* slider section */}
        <section className="section app-slider bg-light" id="app">
          {/* start container */}
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-8">
                <div className="title text-center mb-5">
                  <h6 className="mb-0 fw-bold text-primary">App Screen!</h6>
                  <h2 className="f-40">Show our App Screenshots!</h2>
                  <p className="text-muted">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Dolor eos <br />
                    inventore omnis aliquid rerum alias molestias.
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="swiper-container">
                  <div className="fream-phone ">
                    <img
                      src="/images/testi/phone-fream.png"
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  <Slider {...options} draggable={true} swipeToSlide={1} pauseOnHover={false} pauseOnDotsHover={false}>
                    <div className="swiper-slide border-radius">
                      <img
                        src="/images/testi/ss/s-1.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="swiper-slide border-radius">
                      <img
                        src="/images/testi/ss/s-2.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="swiper-slide border-radius">
                      <img
                        src="/images/testi/ss/s-3.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="swiper-slide border-radius">
                      <img
                        src="/images/testi/ss/s-4.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="swiper-slide border-radius">
                      <img
                        src="/images/testi/ss/s-5.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                    <div className="swiper-slide border-radius">
                      <img
                        src="/images/testi/ss/s-6.jpg"
                        alt=""
                        className="img-fluid"
                      />
                    </div>
                  </Slider>
                  {/* navigation buttons */}
                  <div className="swiper-pagination" />
                </div>
              </div>
            </div>
            {/* end container */}
          </div>
        </section>
        {/* end section */}
        {/* cta section */}
        <section className="section cta">
          <div className="bg-overlay-gradiant" />
          {/* start container */}
          <div className="container position-relative">
            <div className="row">
              <div className="col-lg-6">
                <div className="py-5">
                  <h1 className="display-4 text-white">
                    Build Your ideal workspace today.
                  </h1>
                  <p className="text-white-50 mt-3 f-18">
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                    Iure distinctio vero facilis numquam sapiente! Eaque
                    inventore eveniet repellendus quod maiores nulla.
                  </p>
                  <div className="d-flex mt-4 ">
                    <div className="app-store">
                      <a href>
                        <img
                          src="images/img-appstore.png"
                          alt=""
                          className="img-fluid"
                        />
                      </a>
                    </div>
                    <div className="googleplay">
                      <a href>
                        <img
                          src="images/img-googleplay.png "
                          alt=""
                          className="img-fluid ms-3"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="cta-phone-image">
                  <img src="images/cta-bg.png" alt="" className=" img-fluid" />
                </div>
              </div>
            </div>
          </div>
          {/* end container */}
        </section>
        {/* end section */}
        {/* contact section */}
        <section className="section contact overflow-hidden" id="contact">
          {/* start container */}
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="title text-center mb-5">
                  <h6 className="mb-0 fw-bold text-primary">Contact Us</h6>
                  <h2 className="f-40">Get In Touch!</h2>
                  <p className="text-muted">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Dolor eos <br />
                    inventore omnis aliquid rerum alias molestias.
                  </p>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-5">
                <div className="contact-box">
                  <div className="mb-4">
                    <h4 className=" fw-semibold mb-1">Need Support !</h4>
                    <p className="text-muted">
                      Contact us for a quote , help to join the them.
                    </p>
                  </div>
                  <div className="custom-form mt-4 ">
                    <form
                      method="post"
                      name="myForm"
                      onSubmit="return validateForm()"
                    >
                      <p id="error-msg" style={{ opacity: 1 }}>
                        {/* <div class="alert alert-warning">*Please enter a Name*</div> */}
                      </p>
                      <div id="simple-msg" />
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              name="name"
                              id="name"
                              type="text"
                              className="form-control contact-form"
                              placeholder="Your name"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <input
                              name="email"
                              id="email"
                              type="email"
                              className="form-control contact-form"
                              placeholder="Your email"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group mt-2">
                            <input
                              type="text"
                              className="form-control contact-form"
                              id="subject"
                              placeholder="Your Subject.."
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="form-group mt-2">
                            <textarea
                              name="comments"
                              id="comments"
                              rows={4}
                              className="form-control contact-form h-auto"
                              placeholder="Your message..."
                              defaultValue={""}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row my-2">
                        <div className="col-lg-12 d-grid">
                          <input
                            type="submit"
                            id="submit"
                            name="send"
                            className="submitBnt btn btn-rounded btn-primary"
                            defaultValue="Send Message"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className="m-5">
                  <div className="position-relative">
                    <div className="contact-map">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d29754.94142818836!2d72.88699279999999!3d21.217263799999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1641286801478!5m2!1sen!2sin"
                        width={550}
                        height={450}
                        style={{ border: 0 }}
                        allowFullScreen
                        loading="lazy"
                      />
                    </div>
                    <div className="map-shape" />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-4">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <i className="mdi mdi-google-maps f-50 text-primary" />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h5 className="mb-1">Location</h5>
                    <p className="f-14 mb-0 text-muted">
                      2276 Lynn Ogden Lane Beaumont
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center mt-4 mt-lg-0">
                  <div className="flex-shrink-0">
                    <i className="mdi mdi-email f-50 text-primary" />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h5 className="mb-1">Email</h5>
                    <p className="f-14 mb-0 text-muted">
                      Email: FredVWeaver@rhyta.com
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="d-flex align-items-center mt-4 mt-lg-0">
                  <div className="flex-shrink-0">
                    <i className="mdi mdi-phone f-50 text-primary" />
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h5 className="mb-1">Phone</h5>
                    <p className="f-14 mb-0 text-muted">
                      2276 Lynn Ogden Lane Beaumont
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* end container */}
        </section>
        {/* end section */}
        {/* footer section */}
        <section className=" section footer bg-dark overflow-hidden">
          <div className="bg-arrow"></div>
          {/* container */}
          <div className="container">
            <div className="row ">
              <div className="col-lg-4">
                <a
                  className="navbar-brand logo text-uppercase"
                  href="index-1.html"
                >
                  <img
                    src="images/logo-footer.png"
                    className="logo-light"
                    alt=""
                    height={30}
                  />
                </a>
                <p className="text-white-50 mt-2 mb-0">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                  Beatae, deserunt expedita! Id sit dicta quidem aperiam aut ad
                  debitis numquam .
                </p>
                <div className="footer-icon mt-4">
                  <div className=" d-flex align-items-center">
                    <a
                      href
                      className="me-2 avatar-sm text-center"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Facebook"
                    >
                      <i className="mdi mdi-facebook f-24 align-middle text-primary" />
                    </a>
                    <a
                      href
                      className="mx-2 avatar-sm text-center"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Twitter"
                    >
                      <i className="mdi mdi-twitter f-24 align-middle text-primary" />
                    </a>
                    <a
                      href
                      className="mx-2 avatar-sm text-center"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Googleplay"
                    >
                      <i className="mdi mdi-google-play f-24 align-middle text-primary" />
                    </a>
                    <a
                      href
                      className="mx-2 avatar-sm text-center"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-title="Linkedin"
                    >
                      <i className="mdi mdi-linkedin f-24 align-middle text-primary" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-2 ">
                <div className="text-start mt-4 mt-lg-0">
                  <h5 className="text-white fw-bold">Product</h5>
                  <ul className="footer-item list-unstyled footer-link mt-3">
                    <li>
                      <a href>Features</a>
                    </li>
                    <li>
                      <a href>Pricing</a>
                    </li>
                    <li>
                      <a href>Get App</a>
                    </li>
                    <li>
                      <a href>Contact</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-2 ">
                <div className="text-start">
                  <h5 className="text-white fw-bold">Policies</h5>
                  <ul className="footer-item list-unstyled footer-link mt-3">
                    <li>
                      <a href>Security &amp; Provciy</a>
                    </li>
                    <li>
                      <a href>Marketplace</a>
                    </li>
                    <li>
                      <a href>Term &amp; Condition</a>
                    </li>
                    <li>
                      <a href>Collection</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4">
                <h5 className="text-white">Subscribe</h5>
                <div className="input-group my-4">
                  <input
                    type="text"
                    className="form-control p-3"
                    placeholder="subscribe"
                    aria-label="subscribe"
                    aria-describedby="basic-addon2"
                  />
                  <a
                    href
                    className="input-group-text bg-primary text-white px-4"
                    id="basic-addon2"
                  >
                    Go
                  </a>
                </div>
                <p className="mb-0 text-white-50">
                  publishes will show up in your Subscriptions feed. You may
                  also start.
                </p>
              </div>
            </div>
          </div>
          {/* end container */}
        </section>
        <section className="bottom-footer py-4">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <p className="mb-0 text-center text-muted">
                  © Votiamo. All Rights Reserved.{" "}
                  <i className="mdi mdi-heart text-danger" /> by{" "}
                  <a href="#" target="_blank" className="text-muted">
                    Votiamo.
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* end footer */}
        {/* Style switcher */}
        {/* end switcher*/}
        {/*Bootstrap Js*/}
        {/* Slider Js */}
        {/*  */}
        {/* counter */}
        {/*  */}
        {/* App Js */}
      </div>
    </React.Fragment>
  );
};

export default Home;
