export const termsHtml = (logo) => {
  return (`
    
<!DOCTYPE html>
<html>
<head>
    <title>Terms&Conditions</title>
     <!-- Latest compiled and minified CSS -->
<link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css">

<!-- jQuery library -->
<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>

<!-- Popper JS -->
<script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.16.0/umd/popper.min.js"></script>

<!-- Latest compiled JavaScript -->
<script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script> 
    <style type="text/css">
        a.navbar-brand {
			display: flex;
			width: 100%;
			margin: 1rem 0;
		}
		img{
			width: 250px;
			margin: auto;
		}
        p{
         al: center;
        }
    </style>
</head>
<body>
    <section class="header_area">
    <div class="header_navbar">
            <div class="row">
                <div class="col-lg-12">
                    <nav class="navbar navbar-expand-lg">
                        <a class="navbar-brand" >
                            <img src="${logo}" alt="Logo">
                        </a></nav>
                    </div>
                    </div>
                    </div>
                    </section>
    <div class="container">
        <p><b><center>TERMS AND CONDITIONS</center></b></p>
<p><b>1. Overview</b></p> 
<p>1-1 What is the document and who we are: You are reading a legal document, which is the agreement between you and the customer, (whom we refer to as 'you', 'your' or the 'Customer' in this document) and us. You agree to the terms of use stipulated hereunder, all of which are called the 'Agreement'. We, Votiamo, are the operators of this application or website, the providers of the site-related services.</p><p> 
1-2 What we do: We provide a platform (including this site, mobile applications, etc.), where we, our local affiliates and other sellers (collectively referred to as the 'Sellers') may list offers for vouchers, goods, getaways and other products (collectively as the 'Items'). As a platform provider, we help facilitate transactions for items on our platform. We are never a buyer and usually not the Seller; we provide a venue for the Sellers, as well as for you, to negotiate and complete transactions. This means that in addition to this Agreement, you will have other contractual relationships with different legal entities when you interact with the site.</p><p> 
1-3 What are all legal relationships: any information via the website is subject to the terms and conditions of this seller and given Votiamo is not a seller, this means that your sale contract would probably be with a different legal entity ( mostly a company from Votiamo group), but it should be noted that any contracts that are formed upon the completion of items information is just between you and the seller (and Votiamo is a party to the transaction, unless Votiamo is the seller). The seller shall be responsible for the items published and for dealing with any requests and any other problem arises from or in relation to the contract concluded between you and the seller. It should also be noted that regarding the voucher, and addition to the sale contract that you would conclude with the voucher seller, there would be another contract between you and the company that already provides the products upon the recovery of your voucher in this work ('merchant'). Concerning any vouchers, we and any seller bear no responsibility for any contracts related to the recovery of your voucher with the merchant. Finally, it should be understood that we are not an agent for the seller or the merchant.</p><p>
1-4 What we do also: we provide a forum for sending or publishing the content of this user as well as sending newsletters and other messages to you. For the purpose of sending an electronic message to you, we need to know little about you. For more information, look at the practices of data collection and processing in our privacy statement.</p><p> 
1-5 Scope of use: The site and our services are for the commercial and personal use only. You may access the site or mobile application only through our app, standard web and mobile browsers and similar consumer applications, but not with any robot, spider, offline reader, site search/retrieval application or other manual or automatic device, tool or process to retrieve, index or data mine our site, via scraping, spidering or otherwise. As an exception, revocable at any time by us, operators of public search engines may use spiders to copy materials from the site for the sole purpose of and solely to the extent necessary for creating publicly available searchable indices of the materials, but not caches or archives of such materials.</p><p>
 1-6 Limited license: Notwithstanding any other term of these Terms of Use, you shall be permitted to crawl, index, display or cache the contents of this site solely for the purposes of (i) displaying the site as part of search engine results, and (ii) optimizing (through accelerated mobile pages, or otherwise) the delivery of the site to other users of the site. Votiamo reserves all rights, including copyright, in its HTML code and content (including code that is optimized for accelerated mobile pages) and nothing in these Terms of Use shall act as an express or implied waiver of any of Votiamo rights, or its ability to enforce the terms of these Terms of Use.</p><p>
1-7 Prohibition of use: We reserve the right to prevent you from use the website or services (or any part thereof)</p><p> 
1-8 Definitions: In this Agreement, we use various defined terms.</p><p><b> 
2 Registering with us and your account</b> </p><p>
2-1 Advantages of registration: based on the function of our website or our mobile application at any time, you might or might not need to register for using many of its functions or for accessing our various services. However, you should register for the subscription or publishing images/videos on the website or mobile application.</p><p>
2-2 User Registration Details: In order to register, you have to provide us with your name, postal code and email address and may be some other personal information.</p><p>
2-3 Passwords: During registration, you will have to provide us with your passwords. You should keep the password confidential and notify us immediately if any unauthorized third party becomes aware of this password and in the event that there is any unauthorized use of your e-mail address or any breach of security known to you. You agree that we are not liable if the person to which the password has been disclosed, uses (and/or deals via) our services and website. It should be noted that you are fully responsible in case of not keeping your password confidential.</p><p>
2-4 Valid e-mail addresses: all accounts shall be registered using a valid personal e-mail address that you could get access to it regularly. Any accounts that have been registered using e-mail address of another person or temporary e-mail addresses without notice may be closed. We might require users to verify of the validity of their accounts if we believe that they use invalid e-mail address.</p><p>
 2-5 Emails: Votiamo may send you administrative and promotional e-mail messages, we may also send you information related to the activity of your account in addition to the updates on our website, services and other promotional offers. We may also send you information about the purchases you made in case of knowing them and we bear no responsibility for any contracting we are not aware of. (You may cancel the subscription in our promotional e-mail messages at any time by clicking over the link of unsubscribe below any of these e-mail correspondences).</p><p><b> 
3 User Content</b></p><p>
3-1 User Content: the website may provide accounts owners and website visitors with various opportunities for presenting or publishing reviews, opinions, advice, evaluations, discussions, comments, messages and responses to questionnaires and other contacts as well as files, images, photographs, video, audio recordings, musical works and other contents ('collectively referred to as '' User Content'). User Content could be sent or published through forums, billboards, discussion groups, conversation rooms, polls, blogs or the other communication messages that are being presented on the website or services or though it regarding so at any time. You may be required to have an account to send the User Content.</p><p> 
3-2 Creator of User Content: In contributing in any user content, you acknowledge and ensure that you are the creator of the User Content, or if you are acting on behalf of the creator, you have an express and prior authority from the creator to present or publish the User Content and you have all necessary licenses to grant license and the grant stated in this agreement regarding this User Content. In addition, you acknowledge and ensure that (or if you were acting on behalf of the creator of User Content, you have ensured that the creator acknowledges and ensures that) sharing the User Contents for the purposes you have defined will not infringe or violate any copyrights, trademarks or any other intellectual property rights or third party rights including advertisement or privacy rights.</p><p>
3-3 Restrictions: You will not upload, publish or provide any protected material under copyrights, trademarks or other property rights on the website without obtaining an explicit permission from the owner of copyrights, trademark or other property rights. Votiamo bears no burden or explicit liability for providing you with indicators, signs or any other thing that could help you in determining whether or not the concerned material is protected under copyrights or trademarks. You will be solely responsible for any harm resulting from any infringement of copyrights, trademarks, property rights or any other harm resulted from this sending. The limitations and other prohibited activities that you should be aware of are listed in section 5 below. It should be noted that the logo of Votiamo is protected under intellectual property law and trademarks.</p><p>
3-4 Use of User Content: Votiamo has the sole and absolute right, not the obligation, in reviewing, amending, publishing, rejecting, removing and controlling User Content. Votiamo has also the sole and absolute right, not the obligation, in disclosing the User Content and the surrounding circumstances by transforming them to a third party at any time and for any reason, including the determination of adherence to this agreement and any operation of rules set by Votiamo in order to fulfill any applicable law or regulation or adopted governmental request. Without limitation of the foregoing, Votiamo shall be entitled to remove any material from the website as per its own and absolute discretion and Votiamo bears no responsibility for any User Content or other information appearing or being removed from the website or at any other place. Votiamo shall not use your User Content and it may never use it.</p><p>
3-5 User Content amendment or removal: at some cases and from time to time, it may be possible to amend or remove User Content that you send or publish via your account. Votiamo does not provide any undertakings or guarantees that User Content that you amend or which will be amended or removed from the website or at any other place or that the User Content will cease to appear on the internet, in search engines, social media websites or in any form, means or other technology.</p><p>
3-6 Public Nature of User Content: You understand and agree that User Content is general and any person (whether or not using Votiamo) may read your User Content without your knowledge. Please do not incorporate any additional personal information or any other information in your 'User Content' unless you wish to disclose it to the public. Votiamo shall have no responsibility for the use or disclose of any personal information or any other information which it discloses regarding User Content. </p><p>
3-7 Disclaimer: Any User Content of any kind made by you or any third party is made by the respective author(s) or distributor(s) and not by Votiamo. Other users may post User Content that is inaccurate, misleading or deceptive. Votiamo shall not endorse and shall not be responsible for any User Content, and will not be liable to you or any other person for any loss or damage caused by your reliance on such User Content. User Content reflects the opinions of the person submitting it and does not reflect the opinion of Votiamo. Votiamo does not control or endorse any User Content, and specifically disclaims any liability concerning or relating to your contribution of, use of, or reliance on any User Content and any actions resulting from your participation in any part of the site or mobile application services, including any objectionable User Content. </p><p>
3-8 Grant: Some of User Content that you send to Votiamo may be presented or you may be granted the option of presenting it regarding your personal information or any part of your personal information, including but not limited to your name, initials of the name, user name, account name of social media user, image, similarities, preferences, voice and location. You grant Votiamo and Votiamo group a right exempted of property rights, permanent, not subject to cancellation, sub-licensed, paid in full, non exclusive and transferable all over the world for the use, distribution, presentation and sale of personal information regarding your User Content, whether the User Content appears alone or as a part of other works and in any form, means or technology whether known or has been recently developed and for licensing these rights through various phases of sub-licensees, all of such without a compensation to you. However, Votiamo shall not use your personal information concerning any User Content.</p><p> 
3-9 License: As between you and Votiamo, you shall retain all of your ownership rights in and to the User Content you submit or post. However, by contributing User Content or other information on or through the site, you grant Votiamo and the Votiamo Group a royalty-free, perpetual, irrevocable, sublicensable, fully paid-up, non-exclusive, transferrable, worldwide right and license to use, reproduce, create derivative works from, publish, edit, translate, distribute, perform, display, transmit, offer for sale and sell the User Content alone or as part of other works in any form, media or technology, whether now known or hereafter developed, and to sublicense such rights through multiple tiers of sub-licensees and without compensation to you. You waive any 'moral rights' or other rights with respect to attribution of authorship or integrity of materials regarding the User Content that you may have under any applicable law under any legal theory. Votiamo and the Votiamo Group's license in any User Content or personal information submitted includes use for promotions, advertising, marketing, market research, merchant feedback, quality control or any other lawful purpose.</p><p> 
3-10 Copyrights Policy and Procedures: Votiamo reserves the right to terminate the right to use the site by anybody who infringes the copyrights of others. If you believe that any material has been posted via the site by a user in a way that constitutes copyright infringement, and you would like to bring this material to Votiamo International's attention, you must provide Votiamo Copyright Agent with the following information: (a) an electronic or physical signature of the person authorized to act on behalf of the owner of the copyrighted work; (b) an identification of the copyrighted work and the location on the site of the allegedly infringing work; (c) a written statement that you have a good faith belief that the disputed use is not authorized by the owner, its agent, or the law; (d) your name and contact information, including telephone number and email address; and (e) a statement by you that the above information in your notice is accurate and, under penalty of perjury, that you are the copyright owner or authorized to act on the copyright owner's behalf. The contact information for Votiamo Copyright Agent for notice of claims of copyright infringement is;support@votiamo.com</p><p>
3-11 Unsolicited Ideas: We do not accept or consider, directly or through any Votiamo or Votiamo Group's employee or agent, unsolicited ideas of any kind, including suggestions relating to new or improved products, enhancements, names or technologies, advertising and marketing campaigns, plans or other promotions. Do not send us (or any of our employees) any unsolicited ideas, suggestions, material, images or other work in any form ('Unsolicited Materials'). If you send us Unsolicited Materials, you understand and agree that the following terms will apply, notwithstanding any referral letter or other terms that accompany them: 1) Votiamo shall have no obligation to review any unsolicited materials, nor to keep any unsolicited materials confidential; and 2) Votiamo will own, and may use and redistribute unsolicited materials for any purpose, without restriction and free of any obligation to acknowledge or compensate you. </p><p><b>
4 Standards and Limitations on Liability of Votiamo</b></p><p>
4-1 The standards we operate under: Votiamo always tries its best at what it does and promises that: </p><p>
4-1-1 We will exercise reasonable care and skill in performing our obligations under this Agreement; </p><p>
4-1-2 We will not contravene the requirements of professional diligence in what we do.</p><p> 
4-2 Section 4 is important: This section takes precedence over all other sections in this Agreement and sets forth our entire Liability in respect of the performance, non-performance, purported performance of this Agreement or the Services or Site or any purchase (or any part of them); or otherwise in relation to this Agreement or the entering into or performance of this Agreement.</p><p> 
4-3 What we are responsible for: Nothing in this Agreement excludes or limits our liability for (1) fraud; (2) death or personal injury caused by negligence; (3) Serious misconduct on our part, excluding misconduct not issued by Votiamo itself; or (4) any liability that cannot be excluded or limited under applicable law. You are obligated to take reasonable steps to avoid or limit damages.</p><p> 
4-4 Categories of loss for which we are not liable for: Regardless of Clause 4.3, and since we are only the ones providing the site and services for your non-commercial use, we shall never be liable for: </p><p>
4-4-1 Loss of income or revenue;</p><p> 
4-4-2 loss of actual or anticipated profits;</p><p> 
3-4-4 business loss; or </p><p>
4-4-4 Indirect or consequential loss (and to avoid doubt, we understand that the words 'consequential loss' mean 'consequential losses, whether those losses are predictable, known, foreseeable, or otherwise') </p><p><b>
5- Your Obligations </b></p><p>
5-1 Accurate information: You undertake that all information provided upon registration and received as part of your account during the course of this agreement is true, complete and accurate and that you are responsible for it and that you will inform us immediately of any changes to this information by updating the details in our account.</p><p> 
5-2 Website Content: It is your responsibility to ensure that any elements or information (or products) available on the website meet your specific requirements.</p><p> 
5-3 What You Cannot Do: You undertake without restriction not to use or permit anyone else to use the Services or the Website. </p><p>
5-3-1 Upload, send or receive any material that is not civil or tasteful, including user content.</p><p> 
5-3-2 Upload, send or receive any material, including user content that is unlawful, harmful, causing damage, threatening, abusive, inconvenient, hateful, racist, anti-gay, infringing, pornographic, cruel, misleading or grossly offensive in nature, unethical, obscene, threatening, infidel, defamatory, or offensive character by any person or entity that violates the court, violates trust, or violates the rights of a person or other body, including copyrights, trademarks, trade secrets, patents, or personal rights, advertising, privacy, or other third-party rights;</p><p> 
5-3-3 Upload, send or receive any materials, including user content regarding which you have not received all necessary licenses and /or approvals (from us or third parties); or who constitutes or encourages behavior that would be considered a criminal offense or cause civil liability or otherwise be against the law or violate the rights of any third party in any country in the world;</p><p> 
5-3-4 Upload, send or receive any material that is technically harmful (including computer viruses, logic bombs, trojans, worms, harmful components, corrupt data, malware, harmful data, or anything else that can interfere with, corrupt or in another way causes loss, damage, harm or restriction of the performance of any program or computer equipment).</p><p> 
5-3-5 Causing unwanted annoyance, harassment, or anxiety; </p><p>
5-3-6 Intercept or attempt to intercept any correspondence sent through the communication system;</p><p> 
5-3-7 For a purpose contrary to what we have determined or intended to use;</p><p> 
5-3-8 For any fraudulent purpose; or </p><p>
5-3-9 In any way where its purpose is to incite hatred against any ethnic or religious minority or any other minority, or it is intended in another way to influence an individual, group or body adversely. </p><p>
5-4 Prohibited Uses: The following uses are expressly prohibited and you undertake not to (or permit anyone else to do) any of the following: </p><p>
5-4-1 Resell the services or the website; </p><p>
5-4-2 Providing wrong data including wrong names, addresses, contact details and fraudulent use of credit/debit card numbers; </p><p>
5-4-3 Attempting to circumvent our security or network, including accessing data not intended for you, logging in to a server or account that you are not expressly permitted to access, or exploring security or other networks (such as running a search for portals);</p><p> 
5-4-4 Accessing the services (on the site) in one of the ways related to or committing any act that would impose an unreasonable and disproportionate burden on our infrastructure or in another way that interferes with its work, efficiency or operation; </p><p>
5-4-5 Performing any form of network monitoring that intercepts data not intended for you;</p><p> 
5-4-6 Sending unsolicited mail messages, including: Sending 'spam' or other advertising material to individuals who do not specifically request this material. You are expressly prohibited from sending unwanted bulk mail. This includes the heavy transmission of commercial, promotional or informational advertisements, and political or religious publications, and these materials should only be sent to people who explicitly request them. In the event that the recipient requests to stop receiving e-mail of this nature, you may not send any other mail to this person; </p><p>
5-4-7 Create or transmit 'chain letters' or other 'hierarchical diagrams', whether or not the recipient wishes to receive these messages; </p><p>
5-4-8 Sending malicious mail, including overflowing a user or website, with massive or large email messages; </p><p>
5-4-9 Conclude fraudulent interactions or transactions with us, a seller, or a merchant (this includes intentionally concluding interactions or transactions on behalf of the third party so that you do not have the power to bind this third party or your enemies to be the third party);</p><p> 
5-4-10 Using the services or the website (for any specific mission or any of them) in contravention of this agreement; </p><p>
5-4-11 Unauthorized use of the email address information or forging it;</p><p> 
5-4-12 Engaging in any illegal or criminal activity regarding the use of the services and / or the website or any coupon; or </p><p>
5-4-13 Copying or using any user content for commercial purposes. </p><p><b>
6- Rules for using the Services and the Site </b></p><p>
6-1 Errors and deficiencies: We shall not be responsible for errors or deficiencies if we comply with the standards specified in Section 4 of this Agreement. We will use our reasonable endeavors to correct any errors or deficiencies as soon as practicable after notification. We reserve the right to change, amend, replace, discontinue or remove any section, elements, or information on the site without notice or forming part of the services from time to time. </p><p>
6-2 Viruses and similar: We do not provide any guarantee that the services or the site are free of viruses or anything else that may have a detrimental effect on any technology for customers and subscribers, and we (the platform) have the right to request compensation in the event that the person who caused the publication, suspension or destruction, deletion, erasing or transfer of information or disruption of the website or platform. </p><p>
6-3 Ceasing access: Although we try to allow uninterrupted access to the services and the site, it is permissible to stop, restrict or terminate access to the services and sites at any time, and your access to the site and / or services may sometimes be restricted in order to provide repairs, maintenance, or introduction of new facilities or services. We will attempt to restore this access as soon as reasonably possible. We also reserve our right to prohibit access to and/or modify or remove any materials that, in our reasonable opinion, may cause a breach of any of this agreement.</p><p> 
6-4 Closing accounts: We reserve the right to close accounts in the event that any user violates our terms of use (i.e. this agreement), including: in the event that they are seen using IP addresses in order to try to hide the use of multiple accounts, or in the event that one of the users claims that they exist in a different country than he actually lives, or if the website or services are obstructed in any way.</p><p> 
6-5 Multiple Accounts: in the event that multiple accounts are used and we take any action against you, action can be taken against all of your accounts.</p><p><b> 
7- Suspension and Termination </b></p><p>
In the event that we stop the services or the site, we may refuse to restore the services or the site until we receive confirmation from you and in a manner we deem acceptable that there will be no further breach of the provisions of this agreement.</p><p> 
7-1 Votiamo is committed to fully cooperating with any law enforcement authorities or court order that requests or directs Votiamo to disclose the identity or location of any person who violates this agreement. </p><p>
7-2 Without restricting anything else in this Section 7, we have the right immediately or at any time (in whole or in part)</p><p> 1) to discontinue the services and /or the website;</p><p> 2) Stopping your use of the Services and / or the website;</p><p> 3) Stop using the services and / or the website for people whom we believe are connected (in any way) to you; and / or</p><p> 4) Immediately terminate this Agreement in the following cases: </p><p>
7-3-1 Your commission of any breach of this Agreement; </p><p>
7-3-2 We suspect on reasonable grounds that you have or may have committed or will commit a breach of this Agreement; or </p><p>
7-3-3 We suspect on reasonable grounds that you have committed or are committing any fraud against us or any person. </p><p>
7-5 Regardless of anything else in this Section 7, we may terminate this Agreement at any time.</p><p> 
7-6 Our right to terminate this Agreement is without prejudice to any right or remedy of others that we may have regarding any breach of any rights, obligations or responsibilities due prior to termination.</p><p><b> 
8- Data Protection </b></p><p>
8-1 Please see our Privacy Policy, which forms part of this Agreement.</p><p> <b>
9- Advertisements </b></p><p>
9-1 Any advertisements may be sent on our behalf by a third-party advertising company, which may place or acknowledge on our behalf a unique 'cookie' on our browser. In case you want more information about this practice and know our options about not using this Information from any company, see the privacy policy.</p><p><b> 
10- Links to and from other sites </b></p><p>
10-1 The site includes other links to third-party websites and to sources provided by third parties (together 'other sites'). These other sites are linked only for the purpose of providing information only and are exclusively for your convenience. We have no control over, do not accept, and are not responsible for other sites or for the content or products of other sites (including those related to social networking sites such as Facebook) and we do not accept any responsibility for any loss or damage that may arise from their use. In the event that you decide to access any of the third-party sites connected to the site, you do so entirely at your own risk.</p><p><b> 
11- Tax </b></p><p>
11-1 All prices include value added tax where applicable as of 1 January 2018. The effective date of the application of the tax law</p><p> 
12- Applicable Law These Terms of Service and any separate agreements under which services are provided to you, are governed by the laws of the United Arab Emirates. The site reserves the right to file cases in the courts of the country of your site.</p> <p>
13- Money Laundering In the event that the owner (Votiamo), in his capacity as the owner of the platform, at any time, proves that the sums collected are quasi money laundering, then Votiamo relieves itself from liability completely, and the subscribers must ensure that the sums paid to Votiamo are not obtained from any quasi-criminal. </p><p>
14- Attached to this Agreement is the term and subscription fees Fixed-term agreements (3, 6, 9 and 12 months contracts) </p><p>
1- Votiamo invoices for arrears are presented in the form of a recurring payment schedule.</p><p> 
2- We have a fixed term agreement (3, 6, 9 and 12 month contracts) that will be renewed automatically on the term expiration date, unless otherwise agreed.</p><p> 
3- During the fixed-term agreement, customers are not entitled to stop dealing with Votiamo subscription, unless a special agreement is reached between Votiamo and the subscriber.</p><p> 
4- All packages for professionals and companies are divided into two types:</p><p> 
1- Initial professional package (represented in app marketing and 2 social media platforms) at a value of $ 100/month. </p><p>
2- Professionalism for professionals (represented in app marketing and four social media platforms / Google display ads) at a value of $ 150/month.</p><p> 
3- Corporate principle (represented in app marketing and four social / direct marketing platforms) at a value of $ 250 / month. </p><p>
4- Corporate professionalism (represented in app marketing and six social media platforms / Google ads / direct marketing) at a value of $ 300 / month.</p><p> <b>
Terms of use of Ads </b></p><p>
1- Votiamo will publish weekly 'news' about the subscriber on mobile applications and websites. </p><p>
2- Votiamo will add stories for professionals and companies in the form of a storyboard 3 times a week. </p><p>
3- Votiamo will provide a special service for both professionals and companies, which includes the company logo, photo gallery, products, information and website.</p><p> 
4- Votiamo will make a distinguished advertisement within the professional and corporate subscriber's application using the relevant software package. </p><p>
5- Votiamo will target the audience by location, type of sweets, confectionery component, or festival type. </p><p>
6- Votiamo will do direct marketing such as notification of new events, marketing SMS and marketing emails. </p><p>
7- Votiamo will manage Google ads in Votiamo.</p><p> 
8- Votiamo  is not responsible for responding to messages / comments / dealing with followers of the subscriber's social media channels / pages. </p><p>
9- Votiamo, in cooperation with the subscriber, will create and run advertisements for specific target audiences based on customer's expense for each click / view. </p><p><b>
Termination of subscription to the service </b></p><p>
1- If the subscriber wants to cancel / terminate the subscription with Votiamo, please inform us by phone or in writing. One-month advance notice is required, and in the meantime, a month's payments will be charged. We will continue to serve you for one month until this agreement is fully completed. </p><p>
2- Upon terminating / canceling your agreement with Votiamo, it is necessary to send any unpaid funds. </p><p>
3- The maximum applicable fine will be imposed on any unpaid debts.</p><p>
 4- These terms and conditions can be amended at any time by Votiamo, and the subscriber agrees to continue abiding by them in their amended form. We will post the revised terms and conditions on our website – and we will not separately inform you of these changes. </p><p>
5- Once the subscriber finishes subscribing, his professionals / affiliates receive a free account for nonsubscribers (limited access account).</p>
</p>
    </div></body></html>
`)
};