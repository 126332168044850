import React from "react";
import Lottie from "lottie-react";
import animation from "./loader.json";

const Loader = () => {
  return (
    <React.Fragment>
      <div style={{ width: "100%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Lottie style={{ width: "200px", height: "200px" }} animationData={animation} loop={true} />
      </div>
    </React.Fragment>
  )
};

export default Loader;