import React, { useEffect, useState } from "react";
import Loader from "../loader/Loader";
import Navigation from "../components/Navigation";
import { useDispatch, useSelector } from "react-redux";
import "./Survey.css";
import MultiRangeSlider from "../components/MultiRangeSlider";
import Offcanvas from "react-bootstrap/Offcanvas";
import * as surveyActions from "../redux/survey/survey.actions";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getSurveyResultsArray } from "../helper";
import Graph from "./Graph";
import MatchUser from "./MatchUser";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SurveyResult = () => {

  const [resetKey, setResetKey] = useState(0);
  const [filterVisible, setFilterVisible] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [searchCountry, setSearchCountry] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [stateList, setStateList] = useState([]);
  const [searchState, setSearchState] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [cityList, setCityList] = useState([]);
  const [searchCity, setSearchCity] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [regionList, setRegionList] = useState([]);
  const [searchRegion, setSearchRegion] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [provinciaList, setProvinciaList] = useState([]);
  const [searchProvincia, setSearchProvincia] = useState("");
  const [selectedProvincia, setSelectedProvincia] = useState("");
  const [comuneList, setComuneList] = useState([]);
  const [searchComune, setSearchComune] = useState("");
  const [selectedComune, setSelectedComune] = useState("");
  const [civilList, setCivilList] = useState([]);
  const [searchCivil, setSearchCivil] = useState("");
  const [selectedCivil, setSelectedCivil] = useState("");
  const [educationList, setEducationList] = useState([]);
  const [searchEducation, setSearchEducation] = useState("");
  const [selectedEducation, setSelectedEducation] = useState("");
  const [selectGender, setSelectGender] = useState("");
  const [minAge, setMinAge] = useState(0);
  const [maxAge, setMaxAge] = useState(100);
  const [resultData, setResultData] = useState([]);
  const [characAnswers, setCharacAnswers] = useState([]);
  const [userId, setUserId] = useState("");
  const [matchUserModal, setMatchUserModal] = useState(false);
  const [matchAnswers, setMatchAnswers] = useState([]);
  const [matchId, setMatchId] = useState("");
  const [resultLoading, setResultLoading] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState({
    country: false,
    state: false,
    city: false,
    civilState: false,
    education: false,
    region: false,
    provincia: false,
    comune: false
  });
  const { id } = useParams();
  let query = useQuery();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const matchUserToggle = () => setMatchUserModal(!matchUserModal);

  const loading = useSelector((state) => state.surveyInfo.loading);
  const countries = useSelector((state) => state.surveyInfo.countries);
  const states = useSelector((state) => state.surveyInfo.states);
  const cities = useSelector((state) => state.surveyInfo.cities);
  const regions = useSelector((state) => state.surveyInfo.regions);
  const provincias = useSelector((state) => state.surveyInfo.provincias);
  const comunes = useSelector((state) => state.surveyInfo.comunes);
  const civilStateType = useSelector((state) => state.surveyInfo.civilStateType);
  const educationType = useSelector((state) => state.surveyInfo.educationType);
  const questions = useSelector((state) => state.surveyInfo.questions);
  const carachteristicsQuestion = useSelector((state) => state.surveyInfo.carachteristicsQuestion);

  useEffect(() => {
    dispatch(surveyActions.getCountryList());
    dispatch(surveyActions.civilStateTypeList());
    dispatch(surveyActions.educationTypeList());
    dispatch(surveyActions.getSurveyQuestions(id));
    if (query.get('userId')) {
      setUserId(query.get('userId'));
    }
  }, []);

  const getResults = async () => {
    const ids = questions.questions.map(elem => elem._id);
    const datas = await getSurveyResultsArray(id, ids, selectGender, minAge, maxAge, selectedCountry, selectedState, selectedCity, selectedEducation, selectedCivil, selectedRegion, selectedProvincia, selectedComune, characAnswers, userId, setResultLoading);
    setResultData(datas.arr);
    setMatchAnswers(datas.mArr);
  };

  useEffect(() => {
    if (questions?.questions && questions.questions.length > 0) {
      getResults();
    }
  }, [questions]);

  useEffect(() => {
    if (carachteristicsQuestion?.length > 0) {
      setCharacAnswers(carachteristicsQuestion?.map(c => {
        return {
          question: c?._id,
          ans: ""
        }
      }));
    } else {
      setCharacAnswers([]);
    }
  }, [carachteristicsQuestion]);

  useEffect(() => {
    if (selectedCountry) {
      if (selectedCountry === "107") {
        dispatch(surveyActions.getRegionList());
      } else {
        dispatch(surveyActions.getStateList(selectedCountry));
      }
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState) {
      dispatch(surveyActions.getCityList(selectedState));
    }
  }, [selectedState]);

  useEffect(() => {
    if (selectedRegion) {
      dispatch(surveyActions.getProvinciaList(selectedRegion));
    }
  }, [selectedRegion]);

  useEffect(() => {
    if (selectedProvincia) {
      dispatch(surveyActions.getComuneList(selectedProvincia));
    }
  }, [selectedProvincia]);

  useEffect(() => {
    if (civilStateType && civilStateType.length > 0) {
      if (searchCivil) {
        let filteredList = civilStateType.filter(elem => elem.type.toLowerCase().includes(searchCivil.toLowerCase()));
        setCivilList(filteredList);
      } else {
        setCivilList(civilStateType);
      }
    } else {
      setCivilList([]);
    }
  }, [civilStateType, searchCivil]);

  useEffect(() => {
    if (educationType && educationType.length > 0) {
      if (searchEducation) {
        let filteredList = educationType.filter(elem => elem.type.toLowerCase().includes(searchEducation.toLowerCase()));
        setEducationList(filteredList);
      } else {
        setEducationList(educationType);
      }
    } else {
      setEducationList([]);
    }
  }, [educationType, searchEducation]);

  useEffect(() => {
    if (countries && countries.length > 0) {
      if (searchCountry) {
        let filteredList = countries.filter(elem => elem.name.toLowerCase().includes(searchCountry.toLocaleLowerCase()));
        setCountryList(filteredList);
      } else {
        setCountryList(countries);
      }
    } else {
      setCountryList([]);
    }
  }, [countries, searchCountry]);

  useEffect(() => {
    if (states && states.length > 0) {
      if (searchState) {
        let filteredList = states.filter(elem => elem.name.toLowerCase().includes(searchState.toLowerCase()));
        setStateList(filteredList);
      } else {
        setStateList(states);
      }
    } else {
      setStateList([]);
    }
  }, [states, searchState]);

  useEffect(() => {
    if (regions && regions.length > 0) {
      if (searchRegion) {
        let filteredList = regions.filter(elem => elem.DescrRegione.toLowerCase().includes(searchRegion.toLowerCase()));
        setRegionList(filteredList);
      } else {
        setRegionList(regions);
      }
    } else {
      setRegionList([]);
    }
  }, [regions, searchRegion]);

  useEffect(() => {
    if (provincias && provincias.length > 0) {
      if (searchProvincia) {
        let filteredList = provincias.filter(elem => elem.DescrProvincia.toLowerCase().includes(searchProvincia.toLowerCase()));
        setProvinciaList(filteredList);
      } else {
        setProvinciaList(provincias);
      }
    } else {
      setProvinciaList([]);
    }
  }, [provincias, searchProvincia]);

  useEffect(() => {
    if (comunes && comunes.length > 0) {
      if (searchComune) {
        let filteredList = comunes.filter(elem => elem.DescrComune.toLowerCase().includes(searchComune.toLowerCase()));
        setComuneList(filteredList);
      } else {
        setComuneList(comunes);
      }
    } else {
      setComuneList([]);
    }
  }, [comunes, searchComune]);

  useEffect(() => {
    if (cities && cities.length > 0) {
      if (searchCity) {
        let filteredList = cities.filter(elem => elem.name.toLowerCase().includes(searchCity.toLowerCase()));
        setCityList(filteredList);
      } else {
        setCityList(cities);
      }
    } else {
      setCityList([]);
    }
  }, [cities, searchCity]);

  const toggleDropdown = (dropdown) => {
    setDropdownVisible((prevState) => ({
      country: dropdown === "country" ? !prevState.country : false,
      state: dropdown === "state" ? !prevState.state : false,
      city: dropdown === "city" ? !prevState.city : false,
      civilState: dropdown === "civilState" ? !prevState.civilState : false,
      education: dropdown === "education" ? !prevState.education : false,
      region: dropdown === "region" ? !prevState.region : false,
      provincia: dropdown === "provincia" ? !prevState.provincia : false,
      comune: dropdown === "comune" ? !prevState.comune : false
    }));
    setSearchCountry("");
    setSearchState("");
    setSearchCity("");
    setSearchRegion("");
    setSearchProvincia("");
    setSearchComune("");
    setSearchCivil("");
    setSearchEducation("");
  };

  const handleClearAllFilter = async () => {
    setResetKey(resetKey + 1);
    setSelectedCountry("");
    setSelectedState("");
    setSelectedCity("");
    setSelectedRegion("");
    setSelectedProvincia("");
    setSelectedComune("");
    setSelectGender("");
    setSelectedCivil("");
    setSelectedEducation("");
    setMinAge(0);
    setMaxAge(100);
    const ids = questions.questions.map(elem => elem._id);
    if (carachteristicsQuestion?.length > 0) {
      setCharacAnswers(carachteristicsQuestion?.map(c => {
        return {
          question: c?._id,
          ans: ""
        }
      }));
    } else {
      setCharacAnswers([]);
    }
    const datas = await getSurveyResultsArray(id, ids, "", 0, 100, "", "", "", "", "", "", "", "", [], userId, setResultLoading);
    setResultData(datas.arr);
    setMatchAnswers(datas.mArr);
  };

  const handleApplyFilter = () => {
    getResults();
  };

  const handleSearch = (key) => {
    const foundItem = resultData.find(item => Object.keys(item)[0] === key);
    if (foundItem) {
      return foundItem[key];
    } else {
      return [];
    }
  };
  console.log('matchAnswers', matchAnswers)

  return (
    <>
      {/* <Navigation /> */}
      {loading ? (
        <Loader />
      ) : (
        <>
          <section className="survey" style={{ marginTop: "30px" }}>
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <span className="page_breadcrumb">
                    <img
                      src={`${process.env.PUBLIC_URL}/images/survey/backIcon.png`}
                    />
                    <ul>
                      <li onClick={e => navigate("/survey")}>All</li>
                      <li onClick={e => navigate(`/survey-details/${id}`)}>Survey</li>
                      <li>Survey Result</li>
                    </ul>
                  </span>
                </div>
                <div className="col-12">
                  {window.innerWidth > 767 ? (
                    <h5 className="survey_result_heading">Filter</h5>
                  ) : (
                    <>
                      <button
                        onClick={() => setFilterVisible(!filterVisible)}
                        className="filter_btn"
                      >
                        Filter{" "}
                        <img
                          src={`${process.env.PUBLIC_URL}/images/survey/filterIcon.svg`}
                        />
                      </button>
                      <Offcanvas
                        show={filterVisible}
                        onHide={() => setFilterVisible(!filterVisible)}
                        backdrop="static"
                      >
                        <Offcanvas.Header closeButton>
                          <Offcanvas.Title className="survey_result_heading my-3">
                            Filter
                          </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <div
                            className={`mt-3 px-3 filter_responsive ${filterVisible && "active"
                              }`}
                          >
                            <div>
                              <div className="gender_filter mt-3">
                                <div className="row">
                                  <div className="col-12">
                                    <span className="gender_filter_title">
                                      Gender
                                    </span>
                                  </div>
                                  <div className="col-6">
                                    <div className="gender_filter_btn">
                                      <input
                                        type="radio"
                                        name="survey-result-gender-filter"
                                        checked={selectGender === "male"}
                                        onClick={e => {
                                          if (selectGender === "male") {
                                            setSelectGender("");
                                          } else {
                                            setSelectGender("male");
                                          }
                                        }}
                                      />
                                      <div>
                                        <div>
                                          <img
                                            src={`${process.env.PUBLIC_URL}/images/survey/tick.png`}
                                          />
                                        </div>
                                        <span>Male</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-6">
                                    <div className="gender_filter_btn">
                                      <input
                                        type="radio"
                                        name="survey-result-gender-filter"
                                        checked={selectGender === "female"}
                                        onClick={e => {
                                          if (selectGender === "female") {
                                            setSelectGender("");
                                          } else {
                                            setSelectGender("female");
                                          }
                                        }}
                                      />
                                      <div>
                                        <div>
                                          <img
                                            src={`${process.env.PUBLIC_URL}/images/survey/tick.png`}
                                          />
                                        </div>
                                        <span>Female</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="age_filter mt-3">
                                <div className="row">
                                  <div className="col-12">
                                    <span className="age_filter_title mt-1">
                                      Age <span>{minAge}-{maxAge}</span>
                                    </span>
                                  </div>
                                  <div className="col-12">
                                    <MultiRangeSlider
                                      rangeLabel="forAge"
                                      key={resetKey}
                                      min={1}
                                      max={100}
                                      onChange={(range) => {
                                        setMinAge(range.min);
                                        setMaxAge(range.max);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="dropdown_filter mt-5">
                                <div className="dropdown_filter_individual">
                                  <div
                                    onClick={() => toggleDropdown("country")}
                                    className={`dropdown_heading ${dropdownVisible.country && "active"
                                      }`}
                                  >
                                    Country<span>{selectedCountry ? countries.find(elem => elem.id === selectedCountry)?.name : "All"}</span>
                                  </div>
                                  <div
                                    className={`dropdown_content ${dropdownVisible.country && "active"
                                      }`}
                                  >
                                    <input type="text" placeholder="Search Country" value={searchCountry} onChange={e => setSearchCountry(e.target.value)} />
                                    <ul className="dropdown_select">
                                      {
                                        countryList && countryList.length > 0 && countryList.map(elem => (
                                          <li className={`${selectedCountry === elem.id ? "selected" : ""}`} key={elem?._id} onClick={e => {
                                            setSearchCountry("");
                                            toggleDropdown("country");
                                            setSelectedCountry(elem.id);
                                            setSelectedState("");
                                            setSelectedCity("");
                                            setSelectedRegion("");
                                            setSelectedProvincia("");
                                            setSelectedComune("");
                                          }}>
                                            {elem?.name}
                                            <img
                                              src={`${process.env.PUBLIC_URL}/images/survey/tick.png`}
                                            />
                                          </li>
                                        ))
                                      }
                                    </ul>
                                  </div>
                                </div>
                                {
                                  selectedCountry && selectedCountry === "107" ?
                                    <>
                                      {
                                        selectedCountry && regions && regions.length > 0 &&
                                        <div className="dropdown_filter_individual">
                                          <div
                                            onClick={() => toggleDropdown("region")}
                                            className={`dropdown_heading ${dropdownVisible.region && "active"
                                              }`}
                                          >
                                            Region<span>{selectedRegion ? regions.find(elem => elem.IdRegione === selectedRegion)?.DescrRegione : "All"}</span>
                                          </div>
                                          <div
                                            className={`dropdown_content ${dropdownVisible.region && "active"
                                              }`}
                                          >
                                            <input type="text" placeholder="Search Region" value={searchRegion} onChange={e => setSearchRegion(e.target.value)} />
                                            <ul className="dropdown_select">
                                              {
                                                regionList && regionList.length > 0 && regionList.map(elem => (
                                                  <li className={`${selectedRegion === elem.IdRegione ? "selected" : ""}`} key={elem?._id} onClick={e => {
                                                    setSearchRegion("");
                                                    toggleDropdown("region");
                                                    setSelectedRegion(elem.IdRegione);
                                                    setSelectedProvincia("");
                                                    setSelectedComune("");
                                                  }}>
                                                    {elem?.DescrRegione}
                                                    <img
                                                      src={`${process.env.PUBLIC_URL}/images/survey/tick.png`}
                                                    />
                                                  </li>
                                                ))
                                              }
                                            </ul>
                                          </div>
                                        </div>
                                      }
                                      {
                                        selectedRegion && provincias && provinciaList.length > 0 &&
                                        <div className="dropdown_filter_individual">
                                          <div
                                            onClick={() => toggleDropdown("provincia")}
                                            className={`dropdown_heading ${dropdownVisible.provincia && "active"
                                              }`}
                                          >
                                            Provincia<span>{selectedProvincia ? provincias.find(elem => elem.IdProvincia === selectedProvincia)?.DescrProvincia : "All"}</span>
                                          </div>
                                          <div
                                            className={`dropdown_content ${dropdownVisible.provincia && "active"
                                              }`}
                                          >
                                            <input type="text" placeholder="Search Provincia" value={searchProvincia} onChange={e => setSearchProvincia(e.target.value)} />
                                            <ul className="dropdown_select">
                                              {
                                                provinciaList && provinciaList.length > 0 && provinciaList.map(elem => (
                                                  <li className={`${selectedProvincia === elem.IdProvincia ? "selected" : ""}`} key={elem?._id} onClick={e => {
                                                    setSearchProvincia("");
                                                    toggleDropdown("provincia");
                                                    setSelectedProvincia(elem.IdProvincia);
                                                    setSelectedComune("");
                                                  }}>
                                                    {elem?.DescrProvincia}
                                                    <img
                                                      src={`${process.env.PUBLIC_URL}/images/survey/tick.png`}
                                                    />
                                                  </li>
                                                ))
                                              }
                                            </ul>
                                          </div>
                                        </div>
                                      }
                                      {
                                        selectedProvincia && comunes && comunes.length > 0 &&
                                        <div className="dropdown_filter_individual">
                                          <div
                                            onClick={() => toggleDropdown("comune")}
                                            className={`dropdown_heading ${dropdownVisible.comune && "active"
                                              }`}
                                          >
                                            Comune<span>{selectedComune ? comunes.find(elem => elem.IdComune === selectedComune)?.DescrComune : "All"}</span>
                                          </div>
                                          <div
                                            className={`dropdown_content ${dropdownVisible.comune && "active"
                                              }`}
                                          >
                                            <input type="text" placeholder="Search Comune" value={searchComune} onChange={e => setSearchComune(e.target.value)} />
                                            <ul className="dropdown_select">
                                              {
                                                comuneList && comuneList.length > 0 && comuneList.map(elem => (
                                                  <li className={`${selectedComune === elem.IdComune ? "selected" : ""}`} key={elem?._id} onClick={e => {
                                                    setSearchComune("");
                                                    toggleDropdown("comune");
                                                    setSelectedComune(elem.IdComune);
                                                  }}>
                                                    {elem?.DescrComune}
                                                    <img
                                                      src={`${process.env.PUBLIC_URL}/images/survey/tick.png`}
                                                    />
                                                  </li>
                                                ))
                                              }
                                            </ul>
                                          </div>
                                        </div>
                                      }
                                    </>
                                    :
                                    <>
                                      {
                                        selectedCountry && states && states.length > 0 &&
                                        <div className="dropdown_filter_individual">
                                          <div
                                            onClick={() => toggleDropdown("state")}
                                            className={`dropdown_heading ${dropdownVisible.state && "active"
                                              }`}
                                          >
                                            State<span>{selectedState ? states.find(elem => elem.id === selectedState)?.name : "All"}</span>
                                          </div>
                                          <div
                                            className={`dropdown_content ${dropdownVisible.state && "active"
                                              }`}
                                          >
                                            <input type="text" placeholder="Search State" value={searchState} onChange={e => setSearchState(e.target.value)} />
                                            <ul className="dropdown_select">
                                              {
                                                stateList && stateList.length > 0 && stateList.map(elem => (
                                                  <li className={`${selectedState === elem.id ? "selected" : ""}`} key={elem?._id} onClick={e => {
                                                    setSearchState("");
                                                    toggleDropdown("state");
                                                    setSelectedState(elem.id);
                                                    setSelectedCity("");
                                                  }}>
                                                    {elem?.name}
                                                    <img
                                                      src={`${process.env.PUBLIC_URL}/images/survey/tick.png`}
                                                    />
                                                  </li>
                                                ))
                                              }
                                            </ul>
                                          </div>
                                        </div>
                                      }
                                      {
                                        selectedState && cities && cities.length > 0 &&
                                        <div className="dropdown_filter_individual">
                                          <div
                                            onClick={() => toggleDropdown("city")}
                                            className={`dropdown_heading ${dropdownVisible.city && "active"
                                              }`}
                                          >
                                            City<span>{selectedCity ? cities.find(elem => elem.id === selectedCity)?.name : "All"}</span>
                                          </div>
                                          <div
                                            className={`dropdown_content ${dropdownVisible.city && "active"
                                              }`}
                                          >
                                            <input type="text" placeholder="Search City" value={searchCity} onChange={e => setSearchCity(e.target.value)} />
                                            <ul className="dropdown_select">
                                              {
                                                cityList && cityList.length > 0 && cityList.map(elem => (
                                                  <li className={`${selectedCity === elem.id ? "selected" : ""}`} key={elem?._id} onClick={e => {
                                                    setSearchCity("");
                                                    toggleDropdown("city");
                                                    setSelectedCity(elem.id);
                                                  }}>
                                                    {elem?.name}
                                                    <img
                                                      src={`${process.env.PUBLIC_URL}/images/survey/tick.png`}
                                                    />
                                                  </li>
                                                ))
                                              }
                                            </ul>
                                          </div>
                                        </div>
                                      }
                                    </>
                                }
                                {
                                  civilStateType && civilStateType.length > 0 &&
                                  <div className="dropdown_filter_individual">
                                    <div
                                      onClick={() => toggleDropdown("civilState")}
                                      className={`dropdown_heading ${dropdownVisible.civilState && "active"
                                        }`}
                                    >
                                      Civil State<span>{selectedCivil ? civilList.find(elem => elem._id === selectedCivil)?.type : "All"}</span>
                                    </div>
                                    <div
                                      className={`dropdown_content ${dropdownVisible.civilState && "active"
                                        }`}
                                    >
                                      <input type="text" placeholder="Search Civil State" value={searchCivil} onChange={e => setSearchCivil(e.target.value)} />
                                      <ul className="dropdown_select">
                                        {
                                          civilList && civilList.length > 0 && civilList.map(elem => (
                                            <li className={`${selectedCivil === elem._id ? "selected" : ""}`} key={elem?._id} onClick={e => {
                                              setSearchCivil("");
                                              toggleDropdown("civilState");
                                              setSelectedCivil(elem._id);
                                            }}>
                                              {elem?.type}
                                              <img
                                                src={`${process.env.PUBLIC_URL}/images/survey/tick.png`}
                                              />
                                            </li>
                                          ))
                                        }
                                      </ul>
                                    </div>
                                  </div>
                                }
                                {
                                  educationType && educationType.length > 0 &&
                                  <div className="dropdown_filter_individual">
                                    <div
                                      onClick={() => toggleDropdown("education")}
                                      className={`dropdown_heading ${dropdownVisible.education && "active"
                                        }`}
                                    >
                                      Education<span>{selectedEducation ? educationList.find(elem => elem._id === selectedEducation)?.type : "All"}</span>
                                    </div>
                                    <div
                                      className={`dropdown_content ${dropdownVisible.education && "active"
                                        }`}
                                    >
                                      <input
                                        type="text"
                                        placeholder="Search Education"
                                        value={searchEducation}
                                        onChange={e => setSearchEducation(e.target.value)}
                                      />
                                      <ul className="dropdown_select">
                                        {
                                          educationList && educationList.length > 0 && educationList.map(elem => (
                                            <li className={`${selectedEducation === elem._id ? "selected" : ""}`} key={elem?._id} onClick={e => {
                                              setSearchEducation("");
                                              toggleDropdown("education");
                                              setSelectedEducation(elem._id);
                                            }}>
                                              {elem?.type}
                                              <img
                                                src={`${process.env.PUBLIC_URL}/images/survey/tick.png`}
                                              />
                                            </li>
                                          ))
                                        }
                                      </ul>
                                    </div>
                                  </div>
                                }
                                {
                                  carachteristicsQuestion && carachteristicsQuestion.length > 0 &&
                                  <>
                                    <h6 className="mt-3 mb-3">Survey Characteristics</h6>
                                    {
                                      carachteristicsQuestion?.map((c, i) => (
                                        <div className="col-12 mt-3" key={i}>
                                          <label for="validationCustom04" class="form-label">
                                            {c?.question}
                                          </label>
                                          <select
                                            class="form-select"
                                            id="validationCustom04"
                                            required
                                            value={characAnswers[i]?.ans}
                                            onChange={e => {
                                              const modifiedObject = {
                                                ...characAnswers[i],
                                                ans: e.target.value
                                              };

                                              const modifiedArray = [
                                                ...characAnswers.slice(0, i),
                                                modifiedObject,
                                                ...characAnswers.slice(i + 1)
                                              ];

                                              setCharacAnswers(modifiedArray);
                                            }}
                                          >
                                            <option selected value="">
                                              All
                                            </option>
                                            {
                                              c?.options && c?.options?.length > 0 && c?.options?.map(o => (
                                                <option key={o} value={o}>{o}</option>
                                              ))
                                            }
                                          </select>
                                        </div>
                                      ))
                                    }
                                  </>
                                }
                              </div>
                              <div className="filter_btn_group">
                                <button className="apply_filter_btn" onClick={e => {
                                  handleApplyFilter();
                                  setFilterVisible(!filterVisible);
                                }}>
                                  Apply Filter
                                </button>
                                <button className="clear_filter_btn" onClick={e => {
                                  handleClearAllFilter();
                                  setFilterVisible(!filterVisible);
                                }}>
                                  Clear All
                                </button>
                              </div>
                            </div>
                          </div>
                        </Offcanvas.Body>
                      </Offcanvas>
                    </>
                  )}
                </div>
                <div className="col-12">
                  <div className="row mb-5">
                    {window.innerWidth > 767 && (
                      <div
                        className={`col-xl-3 col-lg-4 col-md-5 col-sm-12 mt-3`}
                      >
                        <div className="gender_filter mt-3">
                          <div className="row">
                            <div className="col-12">
                              <span className="gender_filter_title">
                                Gender
                              </span>
                            </div>
                            <div className="col-6">
                              <div className="gender_filter_btn">
                                <input
                                  type="radio"
                                  name="survey-result-gender-filter"
                                  checked={selectGender === "male"}
                                  onClick={e => {
                                    if (selectGender === "male") {
                                      setSelectGender("");
                                    } else {
                                      setSelectGender("male");
                                    }
                                  }}
                                />
                                <div>
                                  <div>
                                    <img
                                      src={`${process.env.PUBLIC_URL}/images/survey/tick.png`}
                                    />
                                  </div>
                                  <span>Male</span>
                                </div>
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="gender_filter_btn">
                                <input
                                  type="radio"
                                  name="survey-result-gender-filter"
                                  checked={selectGender === "female"}
                                  onClick={e => {
                                    if (selectGender === "female") {
                                      setSelectGender("");
                                    } else {
                                      setSelectGender("female");
                                    }
                                  }}
                                />
                                <div>
                                  <div>
                                    <img
                                      src={`${process.env.PUBLIC_URL}/images/survey/tick.png`}
                                    />
                                  </div>
                                  <span>Female</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="age_filter mt-3">
                          <div className="row">
                            <div className="col-12">
                              <span className="age_filter_title mt-1">
                                Age <span>{minAge}-{maxAge}</span>
                              </span>
                            </div>
                            <div className="col-12">
                              <MultiRangeSlider
                                rangeLabel="forAge"
                                key={resetKey}
                                min={1}
                                max={100}
                                onChange={(range) => {
                                  setMinAge(range.min);
                                  setMaxAge(range.max);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="dropdown_filter mt-5">
                          <div className="dropdown_filter_individual">
                            <div
                              onClick={() => toggleDropdown("country")}
                              className={`dropdown_heading ${dropdownVisible.country && "active"
                                }`}
                            >
                              Country<span>{selectedCountry ? countries.find(elem => elem.id === selectedCountry)?.name : "All"}</span>
                            </div>
                            <div
                              className={`dropdown_content ${dropdownVisible.country && "active"
                                }`}
                            >
                              <input type="text" placeholder="Search Country" value={searchCountry} onChange={e => setSearchCountry(e.target.value)} />
                              <ul className="dropdown_select">
                                {
                                  countryList && countryList.length > 0 && countryList.map(elem => (
                                    <li className={`${selectedCountry === elem.id ? "selected" : ""}`} key={elem?._id} onClick={e => {
                                      setSearchCountry("");
                                      toggleDropdown("country");
                                      setSelectedCountry(elem.id);
                                      setSelectedState("");
                                      setSelectedCity("");
                                      setSelectedRegion("");
                                      setSelectedProvincia("");
                                      setSelectedComune("");
                                    }}>
                                      {elem?.name}
                                      <img
                                        src={`${process.env.PUBLIC_URL}/images/survey/tick.png`}
                                      />
                                    </li>
                                  ))
                                }
                              </ul>
                            </div>
                          </div>
                          {
                            selectedCountry && selectedCountry === "107" ?
                              <>
                                {
                                  selectedCountry && regions && regions.length > 0 &&
                                  <div className="dropdown_filter_individual">
                                    <div
                                      onClick={() => toggleDropdown("region")}
                                      className={`dropdown_heading ${dropdownVisible.region && "active"
                                        }`}
                                    >
                                      Region<span>{selectedRegion ? regions.find(elem => elem.IdRegione === selectedRegion)?.DescrRegione : "All"}</span>
                                    </div>
                                    <div
                                      className={`dropdown_content ${dropdownVisible.region && "active"
                                        }`}
                                    >
                                      <input type="text" placeholder="Search Region" value={searchRegion} onChange={e => setSearchRegion(e.target.value)} />
                                      <ul className="dropdown_select">
                                        {
                                          regionList && regionList.length > 0 && regionList.map(elem => (
                                            <li className={`${selectedRegion === elem.IdRegione ? "selected" : ""}`} key={elem?._id} onClick={e => {
                                              setSearchRegion("");
                                              toggleDropdown("region");
                                              setSelectedRegion(elem.IdRegione);
                                              setSelectedProvincia("");
                                              setSelectedComune("");
                                            }}>
                                              {elem?.DescrRegione}
                                              <img
                                                src={`${process.env.PUBLIC_URL}/images/survey/tick.png`}
                                              />
                                            </li>
                                          ))
                                        }
                                      </ul>
                                    </div>
                                  </div>
                                }
                                {
                                  selectedRegion && provincias && provincias.length > 0 &&
                                  <div className="dropdown_filter_individual">
                                    <div
                                      onClick={() => toggleDropdown("provincia")}
                                      className={`dropdown_heading ${dropdownVisible.provincia && "active"
                                        }`}
                                    >
                                      Provincia<span>{selectedProvincia ? provincias.find(elem => elem.IdProvincia === selectedProvincia)?.DescrProvincia : "All"}</span>
                                    </div>
                                    <div
                                      className={`dropdown_content ${dropdownVisible.provincia && "active"
                                        }`}
                                    >
                                      <input type="text" placeholder="Search Provincia" value={searchProvincia} onChange={e => setSearchProvincia(e.target.value)} />
                                      <ul className="dropdown_select">
                                        {
                                          provinciaList && provinciaList.length > 0 && provinciaList.map(elem => (
                                            <li className={`${selectedProvincia === elem.IdProvincia ? "selected" : ""}`} key={elem?._id} onClick={e => {
                                              setSearchProvincia("");
                                              toggleDropdown("provincia");
                                              setSelectedProvincia(elem.IdProvincia);
                                              setSelectedComune("");
                                            }}>
                                              {elem?.DescrProvincia}
                                              <img
                                                src={`${process.env.PUBLIC_URL}/images/survey/tick.png`}
                                              />
                                            </li>
                                          ))
                                        }
                                      </ul>
                                    </div>
                                  </div>
                                }
                                {
                                  selectedProvincia && comunes && comunes.length > 0 &&
                                  <div className="dropdown_filter_individual">
                                    <div
                                      onClick={() => toggleDropdown("comune")}
                                      className={`dropdown_heading ${dropdownVisible.comune && "active"
                                        }`}
                                    >
                                      Comune<span>{selectedComune ? comunes.find(elem => elem.IdComune === selectedComune)?.DescrComune : "All"}</span>
                                    </div>
                                    <div
                                      className={`dropdown_content ${dropdownVisible.comune && "active"
                                        }`}
                                    >
                                      <input type="text" placeholder="Search Comune" value={searchComune} onChange={e => setSearchComune(e.target.value)} />
                                      <ul className="dropdown_select">
                                        {
                                          comuneList && comuneList.length > 0 && comuneList.map(elem => (
                                            <li className={`${selectedComune === elem.IdComune ? "selected" : ""}`} key={elem?._id} onClick={e => {
                                              setSearchComune("");
                                              toggleDropdown("comune");
                                              setSelectedComune(elem.IdComune);
                                            }}>
                                              {elem?.DescrComune}
                                              <img
                                                src={`${process.env.PUBLIC_URL}/images/survey/tick.png`}
                                              />
                                            </li>
                                          ))
                                        }
                                      </ul>
                                    </div>
                                  </div>
                                }
                              </>
                              :
                              <>
                                {
                                  selectedCountry && states && states.length > 0 &&
                                  <div className="dropdown_filter_individual">
                                    <div
                                      onClick={() => toggleDropdown("state")}
                                      className={`dropdown_heading ${dropdownVisible.state && "active"
                                        }`}
                                    >
                                      State<span>{selectedState ? states.find(elem => elem.id === selectedState)?.name : "All"}</span>
                                    </div>
                                    <div
                                      className={`dropdown_content ${dropdownVisible.state && "active"
                                        }`}
                                    >
                                      <input type="text" placeholder="Search State" value={searchState} onChange={e => setSearchState(e.target.value)} />
                                      <ul className="dropdown_select">
                                        {
                                          stateList && stateList.length > 0 && stateList.map(elem => (
                                            <li className={`${selectedState === elem.id ? "selected" : ""}`} key={elem?._id} onClick={e => {
                                              setSearchState("");
                                              toggleDropdown("state");
                                              setSelectedState(elem.id);
                                              setSelectedCity("");
                                            }}>
                                              {elem?.name}
                                              <img
                                                src={`${process.env.PUBLIC_URL}/images/survey/tick.png`}
                                              />
                                            </li>
                                          ))
                                        }
                                      </ul>
                                    </div>
                                  </div>
                                }
                                {
                                  selectedState && cities && cities.length > 0 &&
                                  <div className="dropdown_filter_individual">
                                    <div
                                      onClick={() => toggleDropdown("city")}
                                      className={`dropdown_heading ${dropdownVisible.city && "active"
                                        }`}
                                    >
                                      City<span>{selectedCity ? cities.find(elem => elem.id === selectedCity)?.name : "All"}</span>
                                    </div>
                                    <div
                                      className={`dropdown_content ${dropdownVisible.city && "active"
                                        }`}
                                    >
                                      <input type="text" placeholder="Search City" value={searchCity} onChange={e => setSearchCity(e.target.value)} />
                                      <ul className="dropdown_select">
                                        {
                                          cityList && cityList.length > 0 && cityList.map(elem => (
                                            <li className={`${selectedCity === elem.id ? "selected" : ""}`} key={elem?._id} onClick={e => {
                                              setSearchCity("");
                                              toggleDropdown("city");
                                              setSelectedCity(elem.id);
                                            }}>
                                              {elem?.name}
                                              <img
                                                src={`${process.env.PUBLIC_URL}/images/survey/tick.png`}
                                              />
                                            </li>
                                          ))
                                        }
                                      </ul>
                                    </div>
                                  </div>
                                }
                              </>
                          }
                          {
                            civilStateType && civilStateType.length > 0 &&
                            <div className="dropdown_filter_individual">
                              <div
                                onClick={() => toggleDropdown("civilState")}
                                className={`dropdown_heading ${dropdownVisible.civilState && "active"
                                  }`}
                              >
                                Civil State<span>{selectedCivil ? civilList.find(elem => elem._id === selectedCivil)?.type : "All"}</span>
                              </div>
                              <div
                                className={`dropdown_content ${dropdownVisible.civilState && "active"
                                  }`}
                              >
                                <input type="text" placeholder="Search Civil State" value={searchCivil} onChange={e => setSearchCivil(e.target.value)} />
                                <ul className="dropdown_select">
                                  {
                                    civilList && civilList.length > 0 && civilList.map(elem => (
                                      <li className={`${selectedCivil === elem._id ? "selected" : ""}`} key={elem?._id} onClick={e => {
                                        setSearchCivil("");
                                        toggleDropdown("civilState");
                                        setSelectedCivil(elem._id);
                                      }}>
                                        {elem?.type}
                                        <img
                                          src={`${process.env.PUBLIC_URL}/images/survey/tick.png`}
                                        />
                                      </li>
                                    ))
                                  }
                                </ul>
                              </div>
                            </div>
                          }
                          {
                            educationType && educationType.length > 0 &&
                            <div className="dropdown_filter_individual">
                              <div
                                onClick={() => toggleDropdown("education")}
                                className={`dropdown_heading ${dropdownVisible.education && "active"
                                  }`}
                              >
                                Education<span>{selectedEducation ? educationList.find(elem => elem._id === selectedEducation)?.type : "All"}</span>
                              </div>
                              <div
                                className={`dropdown_content ${dropdownVisible.education && "active"
                                  }`}
                              >
                                <input
                                  type="text"
                                  placeholder="Search Education"
                                  value={searchEducation}
                                  onChange={e => setSearchEducation(e.target.value)}
                                />
                                <ul className="dropdown_select">
                                  {
                                    educationList && educationList.length > 0 && educationList.map(elem => (
                                      <li className={`${selectedEducation === elem._id ? "selected" : ""}`} key={elem?._id} onClick={e => {
                                        setSearchEducation("");
                                        toggleDropdown("education");
                                        setSelectedEducation(elem._id);
                                      }}>
                                        {elem?.type}
                                        <img
                                          src={`${process.env.PUBLIC_URL}/images/survey/tick.png`}
                                        />
                                      </li>
                                    ))
                                  }
                                </ul>
                              </div>
                            </div>
                          }
                          {
                            carachteristicsQuestion && carachteristicsQuestion.length > 0 &&
                            <>
                              <h6 className="mt-3 mb-3">Survey Characteristics</h6>
                              {
                                carachteristicsQuestion?.map((c, i) => (
                                  <div className="col-12 mt-3" key={i}>
                                    <label for="validationCustom04" class="form-label">
                                      {c?.question}
                                    </label>
                                    <select
                                      class="form-select"
                                      id="validationCustom04"
                                      required
                                      value={characAnswers[i]?.ans}
                                      onChange={e => {
                                        const modifiedObject = {
                                          ...characAnswers[i],
                                          ans: e.target.value
                                        };

                                        const modifiedArray = [
                                          ...characAnswers.slice(0, i),
                                          modifiedObject,
                                          ...characAnswers.slice(i + 1)
                                        ];

                                        setCharacAnswers(modifiedArray);
                                      }}
                                    >
                                      <option selected value="">
                                        All
                                      </option>
                                      {
                                        c?.options && c?.options?.length > 0 && c?.options?.map(o => (
                                          <option key={o} value={o}>{o}</option>
                                        ))
                                      }
                                    </select>
                                  </div>
                                ))
                              }
                            </>
                          }
                        </div>
                        <div className="filter_btn_group">
                          <button className="apply_filter_btn" onClick={e => handleApplyFilter()}>
                            Apply Filter
                          </button>
                          <button className="clear_filter_btn" onClick={e => handleClearAllFilter()}>
                            Clear All
                          </button>
                        </div>
                      </div>
                    )}
                    {
                      resultLoading ?
                        <Loader /> :
                        <>
                          <div className="col-xl-9 col-lg-8 col-md-7 col-sm-12 mt-3">
                            <div className="row">
                              {
                                questions?.questions && questions.questions.length > 0 && questions.questions.map((elem, i) => (
                                  <div className="col-12 mt-3" key={i}>
                                    <div className="survey_questions_card">
                                      <div className="row">
                                        <div className="col-12">
                                          <p className="survey_question_title">
                                            {i + 1}. {elem?.title}
                                          </p>
                                        </div>
                                        <div className="col-lg-6 col-md-12 mt-4 pt-3">
                                          <ul className="survey_question_mcq mb-3">
                                            {
                                              elem?.options && elem.options.length > 0 && elem.options.map((element, index) => (
                                                <li>
                                                  <input
                                                    type="radio"
                                                    name={`mcq${"questionId"}`}
                                                  />
                                                  <span>{index + 1}. {element}</span>
                                                  <img
                                                    src={`${process.env.PUBLIC_URL}/images/survey/tick.png`}
                                                  />
                                                </li>
                                              ))
                                            }
                                          </ul>
                                          {
                                            userId &&
                                            <button className="apply_filter_btn" style={{ width: "150px", padding: "9px 5px" }} onClick={e => {
                                              setMatchId(elem._id);
                                              setMatchUserModal(true);
                                            }}>
                                              Match Answer
                                            </button>
                                          }
                                        </div>
                                        <div className="col-lg-6 col-md-12">
                                          <p className="survey_question_title mb-3">
                                            Statistics
                                          </p>
                                          <Graph data={handleSearch(elem?._id)} qData={elem} />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              }
                            </div>
                          </div>
                        </>
                    }
                  </div>
                </div>
              </div>
            </div>
          </section>
          <MatchUser matchAnswers={matchAnswers} matchUserModal={matchUserModal} matchUserToggle={matchUserToggle} userId={userId} matchId={matchId} />
        </>
      )}
    </>
  );
};

export default SurveyResult;
