import React from 'react';
import Lottie from "lottie-react";
import animation from "./404.json";

const Error404 = () => {
  return (
    <>
      <div style={{ width: "100%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Lottie style={{ width: "50%", height: "50%" }} animationData={animation} loop={true} />
      </div>
    </>
  )
}

export default Error404;