import * as surveyActions from "./survey.actions";

export const surveyFeatureKey = "surveyInfo";

const initialState = {
    loading: false,
    errorMsg: "",
    list: [],
    details: {},
    questions: {},
    carachteristicsQuestion: [],
    countries: [],
    states: [],
    regions: [],
    provincias: [],
    comunes: [],
    cities: [],
    result: [],
    civilStateType: [],
    educationType: []
};

export const reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case surveyActions.GET_PUBLIC_SURVEY_REQUEST:
            return {
                ...state,
                loading: true
            }

        case surveyActions.GET_PUBLIC_SURVEY_SUCCESS:
            return {
                ...state,
                loading: false,
                list: payload.data
            }

        case surveyActions.GET_PUBLIC_SURVEY_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case surveyActions.GET_PUBLIC_SURVEY_DETAILS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case surveyActions.GET_PUBLIC_SURVEY_DETAILS_SUCCESS:
            return {
                ...state,
                loading: false,
                details: payload.data
            }

        case surveyActions.GET_PUBLIC_SURVEY_DETAILS_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // survey questions
        case surveyActions.GET_SURVEY_QUESTIONS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case surveyActions.GET_SURVEY_QUESTIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                questions: payload.data,
                carachteristicsQuestion: payload.carach
            }

        case surveyActions.GET_SURVEY_QUESTIONS_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // get country list
        case surveyActions.GET_COUNTRY_REQUEST:
            return {
                ...state,
                loading: false
            }

        case surveyActions.GET_COUNTRY_SUCCESS:
            return {
                ...state,
                loading: false,
                countries: payload.data
            }

        case surveyActions.GET_COUNTRY_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // get state list
        case surveyActions.GET_STATE_REQUEST:
            return {
                ...state,
                loading: false
            }

        case surveyActions.GET_STATE_SUCCESS:
            return {
                ...state,
                loading: false,
                states: payload.data
            }

        case surveyActions.GET_STATE_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // get city list
        case surveyActions.GET_CITY_REQUEST:
            return {
                ...state,
                loading: false
            }

        case surveyActions.GET_CITY_SUCCESS:
            return {
                ...state,
                loading: false,
                cities: payload.data
            }

        case surveyActions.GET_CITY_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // get region list
        case surveyActions.GET_REGION_LIST_REQUEST:
            return {
                ...state,
                loading: false
            }

        case surveyActions.GET_REGION_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                regions: payload.data
            }

        case surveyActions.GET_REGION_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // get provincia list
        case surveyActions.GET_PROVINCIA_LIST_REQUEST:
            return {
                ...state,
                loading: false
            }

        case surveyActions.GET_PROVINCIA_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                provincias: payload.data
            }

        case surveyActions.GET_PROVINCIA_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // get comune list
        case surveyActions.GET_COMUNE_LIST_REQUEST:
            return {
                ...state,
                loading: false
            }

        case surveyActions.GET_COMUNE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                comunes: payload.data
            }

        case surveyActions.GET_COMUNE_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // survey result
        case surveyActions.GET_SURVEY_RESULT_REQUEST:
            return {
                ...state,
                loading: false
            }

        case surveyActions.GET_SURVEY_RESULT_SUCCESS:
            return {
                ...state,
                loading: false,
                result: payload.data,
                matchAnswers: payload.sameAnswers
            }

        case surveyActions.GET_SURVEY_RESULT_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // get civil state type list
        case surveyActions.GET_CIVIL_STATE_TYPE_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }

        case surveyActions.GET_CIVIL_STATE_TYPE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                civilStateType: payload.data
            }

        case surveyActions.GET_CIVIL_STATE_TYPE_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // get civil state type list
        case surveyActions.GET_EDUCATION_TYPE_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }

        case surveyActions.GET_EDUCATION_TYPE_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                educationType: payload.data
            }

        case surveyActions.GET_EDUCATION_TYPE_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // send friend request
        case surveyActions.SEND_FRIEND_REQUEST_REQUEST:
            return {
                ...state,
                loading: true
            }

        case surveyActions.SEND_FRIEND_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false
            }

        case surveyActions.SEND_FRIEND_REQUEST_FAILURE:
            return {
                ...state,
                loading: false
            }

        default: return state;
    }
};