export const privacyHtml = (logo) => {
  return (`
    <!DOCTYPE html>
<html>
  <head>
    <title>Privacy&Policy</title>
    <!-- Latest compiled and minified CSS -->
    <link
      rel="stylesheet"
      href="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css"
    />

    <!-- jQuery library -->
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>

    <!-- Popper JS -->
    <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.16.0/umd/popper.min.js"></script>

    <!-- Latest compiled JavaScript -->
    <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js"></script>
    <style type="text/css">
      a.navbar-brand {
        display: flex;
        width: 100%;
        margin: 1rem 0;
      }
      img {
        width: 250px;
        margin: auto;
      }
      p {
        al: center;
      }
    </style>
  </head>
  <body>
    <section class="header_area">
      <div class="header_navbar">
        <div class="row">
          <div class="col-lg-12">
            <nav class="navbar navbar-expand-lg">
              <a class="navbar-brand">
                <img src="${logo}" alt="Logo" />
              </a>
            </nav>
          </div>
        </div>
      </div>
    </section>
    <div class="container">
      <p>
        <b style="font-size: 28px"
          ><center>Votiamo App - Privacy Policy</center></b
        >
      </p>
      <p style="font-size: 23px"><b>Policy</b></p>
      <p>
        <b>Votiamo </b> knows that you take your right to privacy very
        seriously, and so do we! We are committed to protecting your privacy.
        Any information provided to us, including the collection, use, and
        maintaining of personal information by <b>Votiamo </b> Team, will never
        be released to others without your prior consent. The information that
        is collected will be exclusively used to enhance your experience while
        using <b>Votiamo App</b> Software.
      </p>
      <p style="font-size: 23px"><b>Use Logs</b></p>
      <p>
        When using our Services, including engaging with, watching or producing
        any content in relation to our Services, certain user log information in
        iFace applications will be automatically collected, including
      </p>
      <p>1 details on when and how often our Services are being used</p>
      <p>2 devices statistics, critical operation paths, errors, or crashes.</p>
      <p>
        These user logs are used to improve our Services and are NOT USED to
        identify and track our users individually.
      </p>
      <p style="font-size: 23px"><b>Advertising</b></p>
      <p>
        We may collect and use information about you, such as your device
        identifier, MAC address, IMEI, geo-location information and IP address
        for the purpose of the tracking of the advertising. This information is
        used together to create a unique user number to help us to better count
        and track advertising based on language, geographic location and other
        relevant details. This information is NOT SOLD to outside parties.
      </p>
      <p style="font-size: 23px"><b>Security</b></p>
      <p>
        We are concerned about safeguarding the confidentiality of your
        information. We provide physical, electronic, and procedural safeguards
        to protect information we process and maintain. For example, we limit
        access to this information to authorized employees and contractors who
        need to know that information in order to operate, develop or improve
        our Application. Please be aware that, although we endeavor to provide
        reasonable security for information we process and maintain, no security
        system can prevent all potential security breaches.
      </p>
      <p style="font-size: 23px"><b>Changes</b></p>
      <p>
        This Privacy Policy may be updated from time to time for any reason. We
        will notify you of any changes to our Privacy Policy by posting the new
        Privacy Policy here and informing you via email or text message. You are
        advised to consult this Privacy Policy regularly for any changes, as
        continued use is deemed approval of all changes. You can check the
        history of this policy by clicking here.
      </p>
      <p style="font-size: 23px"><b>Policy Updates</b></p>
      <p>
        This policy may change from time to time, but if it does, we will post
        any changes up on this page. If there are any significant changes, we
        will provide a more prominent notice on our site.
      </p>
      <p style="font-size: 23px"><b>Contact Us</b></p>
      <p>
        If you have any questions regarding privacy while using the Application,
        or have questions about our practices, please contact us.Email.
      </p>
      <a class="XqQF9c" href="mailto:support@votiamo.com" target="_blank"
        ><strong>support@votiamo.com</strong></a
      >
    </div>
  </body>
</html>

    `)
};